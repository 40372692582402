// x-links-buttons-etc.less


// Links
a {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}



// Arrow-link
.mixin-arrow-link {
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &::before {
    content: "";
    background: url("../images/arrow.svg") no-repeat center;
    background-size: contain;
    padding-right: 24px; // Determines size
    margin-right: 10px;
  }
}
// More-links
.read-more, // Seems used in various places, like img-special
.vrtx-more a, // If specified link in bottom of box.
.vrtx-read-more, // On list pages. Usually redundant. Also not wcag-compliant. (Hidden on UiO).
.all-messages // In feeds if all-messages-link=[true]. Not ideal, due to non-descriptive link text.
{
  .mixin-arrow-link;
}
.vrtx-more {
  margin-top: 25px;
}



// Button-link
.mixin-button-link {
  display: inline-block;
  position: relative;
  padding: 15px 60px 15px 15px;
  text-decoration: none;
  border: 1px solid;
  border-radius: var(--radius);
  &,
  &::after {
    transition: all .3s;
  }
  &::after {
    content: "";
    background: url("../images/arrow.svg") no-repeat center;
    background-size: contain; // Can get pixel-shifting on hover movement. Use px values if picky.
    width: 24px;
    height: 1.5em; // Match with line-height to center on text
    position: absolute;
    right: 20px;
    top: 15px; // Match with container top padding to center on text
  }
  &:focus {
    outline-offset: 4px;
  }
  &:hover {
    &::after {
      right: 5px;
    }
  }
}
.button,
.button-loud,
.button-large {
  .mixin-button-link;
}
.button-loud,
.button-large {
  background-color: black;
  border-color: transparent;
  color: white;
  &::after {
    background-image: url("../images/arrow-white.svg");
  }
}



// Prev/next button mixins
.mixin-prev-button,
.mixin-next-button {
  background-image: url("../images/arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
}
.mixin-prev-button {
  transform: rotate(180deg);
}



// Native buttons and inputs
input,
button,
select {
  font-family: inherit;
	font-size: inherit;
  font-weight: normal;
	line-height: inherit;
  color: inherit;
  background-color: transparent;
  border: 1px solid;
  padding: 5px 10px;
  margin: 0; // for safari
	max-width: 100%;
  &:focus {
    outline-offset: 4px;
  }
  &:hover {
    background-color: var(--color-neutral-tint-95);
  }
}
input {
  border-radius: 0;
}
select,
button,
input[type=submit] {
  border-radius: var(--radius);
}
select,
button,
input[type=submit],
input[type=radio],
input[type=checkbox] {
  &:not(:disabled) {
    cursor: pointer;
  }
}
input[type=radio],
input[type=checkbox] {
  padding: 0; // Needed for Webkit. Added Oct 2024 by Kjetil.
}
select {
  appearance: none; // Remove browser native appearance, mostly for Safari
  background: url("../images/chevron-down.svg") no-repeat center right 10px;
  padding-right: 30px; // Space for chevron
  max-width: 100%;
}
textarea {
  max-width: 100%;
}



// Social list
.vrtx-social-list {
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    margin: 0 15px 15px 0;
  }
  a {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-indent: -100000px; // Hide text except for screen-reader
    transition: all .3s;
    &:hover {
      opacity: .6;
    }
    // Icons. Also using attribute selector, in case of missing class
    &.facebook,
    &[href*=facebook] {
      background-image: url("../images/social/facebook.svg");
    }
    &.instagram,
    &[href*=instagram] {
      background-image: url("../images/social/instagram.svg");
    }
    &.linkedin,
    &[href*=linkedin] {
      background-image: url("../images/social/linkedin.svg");
    }
    &.pinterest,
    &[href*=pinterest] {
      background-image: url("../images/social/pinterest.svg");
    }
    &.snapchat,
    &[href*=snapchat] {
      background-image: url("../images/social/snapchat.svg");
    }
    &.tiktok,
    &[href*=tiktok] {
      background-image: url("../images/social/tiktok.svg");
    }
    &.tripadvisor,
    &[href*=tripadvisor] {
      background-image: url("../images/social/tripadvisor.svg");
    }
    &.twitter,
    &[href*=twitter] {
      background-image: url("../images/social/x.svg");
    }
    &.youtube,
    &[href*=youtube] {
      background-image: url("../images/social/youtube.svg");
    }
    &.bluesky,
    &[href*=bluesky] {
      background-image: url("../images/social/bluesky.svg");
    }
    &.mail {
      background-image: url("../images/social/mail.svg");
    }
  }
}



// Link-list
// Manually set class on ul in ck source.
// Adds arrows before list-items that contain links.
// Maintains list-bullet for items that don't contain link.
.link-list {
  li {
    position: relative;
    &:has(a) {
      list-style: none; // Remove list-marker if contains link
      &::before {
        content: "";
        background: url("../images/arrow.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        left: -30px; // Match padding-left on ul
        width: 20px;
        height: 1.5em; // Match line-height
      }
    }
    a {
      font-weight: bold;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
