// x-study-plan-direction.less


.vrtx-fs-study-model {
  // Direction-form
  form[id*="direction-form-"] {
    margin: 10px 0 40px;
    p {
      margin-bottom: 10px;
    }
    input,
    label {
      cursor: pointer;
    }
    input {
      // Hidden. Using ::before element on label instead
      position: absolute;
      opacity: 0;
    }
    label {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--color-neutral-tint-95);
      width: fit-content;
      padding: 10px 15px;
      line-height: 1.3;
      @media (@screen-below-medium) {
        font-size: var(--font-size-small);
      }
      // Custom radio button
      &::before {
        content: "";
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        border: 4px solid white;
        border-radius: 100%;
        box-shadow: 0 0 0 2px black;
        background-color: white;
      }
      &:hover {
        background-color: var(--color-neutral-tint-90);
      }
    }
    input:focus + label {
      outline: 2px solid black;
      outline-offset: 2px;
    }
    input:checked + label {
      background-color: black;
      color: white;
      &::before {
        background-color: black;
      }
    }
    &.js-handled {
      button[type=submit] {
        display: none
      }
    }
  }
  // H3 (is outside form)
  h3 {
    // Js-generated message shown if no subjects under heading (pendig direction choice)
    .heading-message {
      display: none; // Default hidden. Assuming there are subjects.
      font-size: var(--font-size-base);
      font-weight: normal;
    }
    &.no-siblings-after {
      .heading-message {
        display: block; // Shown if no subjects after
      }
    }
  }
}
