// x-feeds-and-lists.less


// Feed and list page vertical lists (not columns).
@media (@screen-medium) {
  .vrtx-resource,
  .vrtx-feed li,
  .vrtx-event-component-item,
  .vrtx-event-component-main {
    display: flex;
    gap: var(--space);
    .vrtx-list-item-content {
      flex-basis: var(--width-text-max);
    }
  }
  .vrtx-resources .vrtx-image,
  .vrtx-feed .vrtx-image,
  .vrtx-event-component-picture {
    flex-shrink: 0;
    flex-basis: 350px;
    max-width: 47%; // matches column feed @ 768
  }
  // Exceptions (assuming there are fewer exceptions than rules)
  #vrtx-collection .vrtx-resource {
    display: revert;
    max-width: var(--width-text-max);
  }
}



// Feeds/events
.vrtx-feed .items,
.vrtx-event-component {
  display: grid;
  gap: var(--space);
  list-style: none;
  padding: 0;
  margin: 0;
}



// Feed/events item title
.vrtx-feed .item-title,
.vrtx-event-component-title.summary {
  .mixin-h3;
  display: block;
  width: fit-content;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}



// Feed/events list items, common elements
.vrtx-feed li,
.vrtx-event-component-item {
  p,
  ul,
  ol {
    margin-bottom: 15px;
  }
}



// Feed and list page images
.vrtx-image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
    width: 100%;
  }
}



// Time and place container used in events feed, on list page and on event page
.vrtx-time-and-place-container {
  margin-bottom: 15px;
}

// Misc element occurs in events feed, sometimes inside time-and-place, sometimes on its own
.vrtx-event-component-misc {
  margin-bottom: 15px;
}



// Events date-icon
.vrtx-date-icon {
  display: flex;
  flex-wrap: wrap; // In case it needs to
  background-color: var(--color-neutral-tint-opaque-90); // Opaque due to image overlap.
  padding: 15px 20px;
  width: fit-content;
  height: fit-content;
  margin-bottom: 15px;

  // Font size set here for best alignments. Then reduced for selected elements.
  font-size: var(--font-size-large-10);
  line-height: 1.2;

  // Day, month
  .vrtx-date-icon-day,
  .vrtx-date-icon-month {
    display: block;
    text-align: center;
    white-space: nowrap;
  }
  .vrtx-date-icon-day {
    font-weight: bold;
  }
  .vrtx-date-icon-month {
    font-size: var(--font-size-small);
  }
  // Hide month if event is today
  .vrtx-date-icon-today + .vrtx-date-icon-month {
    display: none;
  }
  // Separator when multiple-day events
  .vrtx-date-icon-separator {
    margin: 0 5px;
  }
}
// If image present, place date on top of img.
// NOTE: Vulnerable to wcag text 200% rule if text is large
.vrtx-event-component-item {
  &.with-picture {
    position: relative;
    .vrtx-date-icon {
      position: absolute;
    }
  }
}



// Article feed
.vrtx-feed {
  .feed-title { // If included, setting feed-title=[true] (usually not ideal)
    display: block;
    width: fit-content;
    .mixin-h3;
  }
  .authors,
  .published-date {
    display: block;
    font-size: var(--font-size-small);
    margin-bottom: 15px;
  }
  .authors {
    font-weight: bold;
  }
  .published-date {
    color: var(--color-neutral-tint-25);
  }
  .authors + .published-date {
    margin-top: -15px;
  }
}



// Feed in columns (revert from vertical layout)
[class*=column-feed] {
  .vrtx-feed li,
  .vrtx-event-component-item,
  .vrtx-event-component-main {
    display: revert;
  }
  .vrtx-image,
  .vrtx-event-component-picture {
    max-width: none;
  }
}
// Column variants
.two-column-feed {
  @media (@screen-medium) {
    .vrtx-feed .items,
    .vrtx-event-component {
      grid-template-columns: 1fr 1fr;
    }
  }
}
.three-column-feed {
  @media (@screen-medium) {
    .vrtx-feed .items,
    .vrtx-event-component {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (@screen-large) {
    .vrtx-feed .items,
    .vrtx-event-component {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
.four-column-feed {
  @media (@screen-medium) {
    .vrtx-feed .items,
    .vrtx-event-component {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (@screen-large) {
    .vrtx-feed .items,
    .vrtx-event-component {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}



// Featured feed item
// Adding this class to box makes first feed item full width
.featured-feed-item .vrtx-feed {
  li:first-child {
    grid-column: 1/-1; // span all columns
    @media (@screen-medium) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .vrtx-image {
        display: grid; // Control image height, or Safari stretches to viewport
        img {
          margin: 0;
          height: 100%;
        }
      }
      .vrtx-list-item-content {
        align-self: center;
      }
    }
    @media (@screen-large) {
      .item-title {
        font-size: var(--font-size-large-30);
      }
    }
  }
}



// Event feed featured item
// Same style as for article feed, but markup is different, so some repetition of code
.featured-feed-item .vrtx-event-component-item {
  &:first-child {
    grid-column: 1/-1; // span all columns
    @media (@screen-medium) {
      .vrtx-event-component-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .vrtx-event-component-picture {
        display: grid; // Control image height, or Safari stretches to viewport
        img {
          margin: 0;
          height: 100%;
        }
      }
      .vrtx-list-item-content {
        align-self: center;
      }
    }
    @media (@screen-large) {
      .vrtx-event-component-title.summary {
        font-size: var(--font-size-large-30);
      }
    }
  }
}



// List pages (article-listing, tagview, vrtx-collection..)
.vrtx-resources {
  // List item
  .vrtx-resource {
    margin-bottom: calc(2 * var(--space));
  }
  .publish-date,
  .published-date,
  .lastModified {
    font-size: var(--font-size-small);
    color: var(--color-neutral-tint-25);
    margin-bottom: 15px;
  }
  .vrtx-title {
    .mixin-h3;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  p {
    margin: 0;
  }
}



// List pages: Increase heading bottom space when no intro, subtract if intro.
// Not needed for vrtx-event-listing, which has other heading at page top
body#vrtx-article-listing,
body#vrtx-collection,
body#vrtx-tagview, // Don't think intro can be present on this page type
body#vrtx-project-listing,
body#vrtx-research-group-listing {
  @media (@screen-medium) {
    h1 {
      margin-bottom: 50px; // Extra spacing
    }
    .vrtx-introduction {
      margin-top: -30px; // Subtract h1 spacing
    }
  }
}



// List pages without vrtx-list-item-content wrapper
// Due to absence of wrapper, it's easier to style these in columns
// Possible temp. pending backend-improvements
body#vrtx-tagview,
body#vrtx-research-group-listing {
  .vrtx-resources {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    row-gap: calc(2 * var(--space));
    column-gap: var(--space);
    margin-bottom: calc(2 * var(--space));

    // Each item
    .vrtx-resource {
      display: revert;
      margin: 0;
    }
    .vrtx-image {
      max-width: none;
    }
  }
}



// uh exchange listing
#vrtx-uh-exchange-listing {
  // Temp. add class when in place in prod
  #main h2 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}



// Message feed and -listing page common elements
.vrtx-messages-main-content-wrapper,
body#vrtx-message-listing {
  .vrtx-resource,
  .vrtx-feed li {
    display: revert;
    max-width: var(--width-soft-max);
  }
  .vrtx-message-listing-edit {
    .mixin-button-link;
    flex-shrink: 0;
    font-size: var(--font-size-tiny);
    font-weight: normal;
    padding: 0 5px;
    position: relative;
    top: -2px; // Tweak vertical position
    &::after {
      display: none;
    }
    &:hover {
      background-color: var(--color-neutral-tint-95);
    }
  }
}
// Message feed only
.vrtx-messages-main-content-wrapper {
  // This header has h2 with title and create-button links inside of it
  .vrtx-messages-header {
    // This is always h2. Doesn't follow hierarchy. Change-candidate
    h2 {
      line-height: inherit; // To balance button and arrow alignment
    }
    // Title
    a:first-child {
      display: inline-block;
      margin: 0 10px 10px 0;
    }
  }
  .vrtx-message-listing-create {
    .mixin-button-link;
    font-size: var(--font-size-base);
    font-weight: normal;
  }
  .vrtx-resource {
    margin-bottom: var(--space);
    p {
      margin: 0;
    }
  }
  div.vrtx-title,
  .description {
    margin-bottom: 10px;
  }
  a.vrtx-title {
    .mixin-h3;
    margin: 0 5px 0 0;
  }
  .vrtx-message-line {
    font-size: var(--font-size-small);
    color: var(--color-neutral-tint-25);
  }
  .vrtx-message-line-publish-date {
    margin-right: 5px;
  }
}
// Message listing page only
body#vrtx-message-listing {
  #vrtx-message-listing-create {
    margin-bottom: var(--space);
  }
  a.vrtx-title {
    margin-right: 5px;
  }
  .vrtx-number-of-comments-add-event-container {
    margin-bottom: 20px;
  }
}



// Image listing
.vrtx-image-listing {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: var(--space);
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  img {
    margin-bottom: 10px;
    box-shadow: 0 0 0 1px var(--color-neutral-tint-80);
    transition: all .3s;
  }
  a:hover img {
    transform: scale(1.1);
    box-shadow: 0 5px 20px var(--color-neutral-tint-80);
  }
  .vrtx-image-title a {
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
  }
}



// Search result page
#vrtx-searchview {
  // Advanced search. Code incomplete. Feature possibly deprecated.
  // Js-generated. Page has its own advanced-search.js included in html.

  // Advanced search trigger and container
  #vrtx-show-advanced-search,
  #vrtx-advanced-search-wrapper {
    display: none;
  }
  // Advanced search wrapper
  #vrtx-advanced-search-wrapper {
    background: var(--color-neutral-tint-95);
    padding: 20px;
    margin-bottom: 20px;
    width: fit-content;
    h3 {
      font-size: inherit;
      line-height: inherit;
      margin: 0;
    }
    .vrtx-textfield {
      margin-bottom: 15px;
    }
    label {
      display: block;
      margin-bottom: 5px;
    }
    input,
    .button {
      background-color: white;
    }
    input {
      width: 100%;
    }
    // Submit button (link)
    .button {
      padding: 10px 15px;
      margin-right: 15px;
      &::after {
        display: none;
      }
    }
  }



  // Regular search
  // Main search box, did-you-mean and hit-counter styled to appear as one box
  // Possible temp. Potential markup improvements here.
  .vrtx-big-search,
  #vrtx-search-did-you-mean,
  .hits {
    background: var(--color-neutral-tint-95);
    padding: 20px;
  }
  // Main search box
  // Contains text input and button + advanced search trigger
  .vrtx-big-search {
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      max-width: 600px;
    }
    input,
    button {
      background: white;
    }
    input {
      width: 100%; // Does what max-width should do
    }
    button {
      white-space: nowrap;
    }
    @media (@screen-medium) {
      input,
      button {
        padding: 7px 15px;
      }
      input {
        font-size: var(--font-size-large-10);
      }
    }
  }
  hr {
    display: none;
  }
  // Did-you-mean
  #vrtx-search-did-you-mean {
    padding-top: 0;
    padding-bottom: 0;
  }
  // Hit counter ("Viser 1-5 av...")
  .hits {
    display: block;
    padding-top: 0;
    margin-bottom: 40px;
  }
  // Result (each item)
  .result {
    margin-bottom: 40px;
    h2 {
      font-size: var(--font-size-large-10);
      margin-bottom: 5px;
    }
    .snippet {
      margin-bottom: 5px;
    }
    .footer {
      font-size: var(--font-size-small);
      color: var(--color-neutral-tint-25);
    }
  }
}



// Paging feed wrapper
// Also contains vrtx-subscribe-component and vrtx-feed-link (rss)
.vrtx-paging-feed-wrapper {
  margin-top: 40px;
}
.vrtx-paging-wrapper {
  display: block;
  margin-bottom: 20px;
  a {
    display: inline-block;
    text-decoration: none;
    border: 1px solid;
    border-radius: var(--radius);
    padding: 5px 15px;
    margin: 0 10px 10px 0;
    &:hover {
      background: var(--color-neutral-tint-95);
    }
    &.marked,
    &.vrtx-marked {
      background: black;
      border-color: transparent;
      color: #fff;
    }
  }
}



// Subscribe component. "Abonner"
// Seen at bottom of vrtx-event-listing page
// Contains "Abonner i din kalender" and feed link (rss)
.vrtx-subscribe-component {
  // Removing dropdown functionality, just showing the content instead.
  .vrtx-dropdown-link,
  .vrtx-dropdown-top {
    display: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}



// Read-more links on list pages. Generally not in use. Not good for uu.
.vrtx-resources .vrtx-read-more {
  display: none;
}



// Icons in vrtx-collection page type. Not in use.
#vrtx-collection .vrtx-icon {
  display: none;
}



// Configurable filtered listing
// Requires js file of same name. See js file for more info.
#configurable-filtered-listing {
  .configurable-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: var(--space);
    label {
      display: block;
      margin-bottom: 3px;
    }
  }
  // Hide list until js ready, for performance
  // Prevent flash of all items without pagination
  &:not(.js-ready) {
    .configurable-list {
      display: none;
    }
  }
  // Feedback message with item counter
  .feedback:not(.no-hits) {
    // Subtle heading unless no hits
    h2 {
      font-size: inherit;
      font-weight: normal;
      line-height: inherit;
    }
  }
  .pagination {
    margin-top: calc(2*var(--space));
    button {
      min-width: 44px;
      margin: 0 10px 10px 0;
      &[aria-current] {
        background: black;
        border-color: transparent;
        color: white;
      }
    }
  }
  ul {
    display: grid;
    gap: calc(2*var(--space)) var(--space);
    list-style: none;
    padding: 0;
    margin: 0;
    @media (@screen-large) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (@screen-below-large) and (@screen-medium) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  li {
    position: relative;
    // Inactive class controlled by js to show/hide list-items
    &.inactive {
      display: none;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white; // Must match page background
      transform: scaleX(0);
      transform-origin: left;
      transition: all .4s ease-out;
    }
    // Updated class used for transitions
    &.updated {
      &::before {
        transform: scaleX(1);
        transition: none;
      }
    }
    img {
      aspect-ratio: 3/2;
      object-fit: cover;
      width: 100%;
    }
    h3 {
      margin-bottom: 10px;
    }
    a {
      text-decoration: none;
      &:hover {
        h3 {
          text-decoration: underline;
        }
      }
    }
    span {
      &:not(:last-child)::after {
        content: "•";
        margin-left: 5px;
      }
    }
  }
}



// vrtx-feed-link. "rss strøm fra denne siden"

// seen at bottom of various list pages:
// vrtx-collection, vrtx-article-listing, vrtx-image-listing
// class or id used, depending on context

// #vrtx-feed-link,
// .vrtx-feed-link {
// set display none in local stylesheet to hide
// }


