// x-item-special.less


.vrtx-frontpage-box {
  // Img-special
  &.img-special-left,
  &.img-special-right {
    background: var(--color-neutral-tint-95);
    display: grid;
    @media (@screen-medium) {
      grid-template-columns: 1fr 1fr;
    }
    .vrtx-frontpage-box-picture {
      display: grid; // Control img height (or Safari stretches to viewport)
    }
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1; // Acts as minimum when height set to 100%
      object-fit: cover;
      margin: 0;
    }
    .vrtx-box-content {
      align-self: center;
      padding: 10%;
      @media (@screen-below-medium) {
        padding: 30px 15px;
      }
    }
  }
  // Image-right order
  &.img-special-right {
    .vrtx-frontpage-box-picture {
      @media (@screen-medium) {
        order: 2;
      }
    }
  }



  // Box-special
  &.box-special {
    background-color: var(--color-neutral-tint-95);
    .vrtx-box-content {
      padding: 10%;
      @media (@screen-below-medium) {
        padding: 30px 15px;
      }
    }
    h2 {
      @media (@screen-medium) {
        font-size: var(--font-size-large-40);
        line-height: 1.2;
      }
    }
    p {
      @media (@screen-medium) {
        font-size: var(--font-size-large-20);
      }
    }
    .read-more,
    .button,
    .button-loud,
    .button-large {
      font-size: var(--font-size-base);
    }
  }



  // Link-special
  &.link-special {
    border: 1px solid;
    padding: 10% 15% 12% 10%;
    @media (@screen-below-medium) {
      padding: 20px 50px 20px 15px;
    }
    .vrtx-box-content {
      font-weight: bold;
      line-height: 1.2;
      margin: 0;
      @media (@screen-large) {
        font-size: 7rem;
      }
      @media (@screen-below-large) and (@screen-medium) {
        font-size: var(--font-size-large-40);
      }
      @media (@screen-below-medium) {
        font-size: var(--font-size-large-20);
      }
    }
    p {
      margin: 0;
    }
    a {
      display: block;
      text-decoration: none;

      // Arrow
      &::after {
        content: "";
        position: absolute; // Only breaks to new line when text does
        background: var(--color-neutral-tint-90) url("../images/arrow.svg") no-repeat center;
        background-size: 40px auto;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-left: 20px;
        margin-top: 15px;
        @media (@screen-below-large) {
          background-size: 24px;
          width: 36px;
          height: 36px;
          margin-left: 10px;
        }
        @media (@screen-below-medium) {
          margin-top: 0;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
// In colored row change box backgrounds
.row-all-colored {
  .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right,
    &.box-special {
      background: white;
    }
  }
}
