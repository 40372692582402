// x-layout-rows-and-columns.less


// Main
#main {
  margin-bottom: 100px;
  @media (@screen-medium) {
    min-height: 500px; // Some space to footer in case little content
  }
}



// Grid-container (outer row container)
.grid-container {
  margin-bottom: calc(3 * var(--space));

  // With background-color
  &.row-all-colored {
    background: var(--color-neutral-tint-95);
    padding-top: calc(2 * var(--space));
    padding-bottom: calc(2 * var(--space));
    margin-bottom: calc(2 * var(--space));
  }
  // Manual class to remove bottom space and connect to row below
  &.no-space-bottom {
    &,
    &.row-all-colored {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
// In vortex edit mode
// Allow overflow or row edit options (that appear on hover) can get unreachable
body#vrtx-frontpage.vrtx-mode-edit #main .grid-container {
  overflow: visible;
}



// Column-wrapper
// For row configuration with multiple columns
.column-wrapper {
  display: grid;
  gap: var(--space);
  @media (@screen-large) {
    &.half-half {
      grid-template-columns: 1fr 1fr;
    }
    &.narrow-wide {
      grid-template-columns: 2fr 3fr;
    }
    &.wide-narrow {
      grid-template-columns: 3fr 2fr;
    }
    &.triple {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  // Grid-items
  > * {
    // Prevent overflowing items from becoming wider than self
    // https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
    // Specific to grid layout. Possible temp.
    // Might be safe to do as general rule, but not sure. Keeping it item-specific for now.
    // Add items in parenthesis if needed (comma-separated).
    &:has(pre) {
      min-width: 0;
    }
  }
}



// Limit text-width in frontpage boxes. Manually added class.
.vrtx-frontpage-box {
  &.running-text {
    max-width: var(--width-text-max);
  }
}



// Page types with possible right column (additional-content)
// Just add more if found
#vrtx-article-listing,
#vrtx-event-listing,
#vrtx-structured-project, // This I think is only for an old template (used by hlsenteret)
#vrtx-research-group-listing,
#vrtx-project-listing {
  #vrtx-content {
    @media (@screen-large) {
      // Column-layout for main and additional-content
      // Set display block for non-column layout
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  // Right column. using flex (as opposed to grid), it will only take up space if present
  #vrtx-additional-content {
    background: var(--color-neutral-tint-95);
    padding: 30px;

    @media (@screen-large) {
      // Flex-properties will be ignored if parent is not flex (non-column view)
      flex-shrink: 0;
      flex-basis: 33%;
      margin-left: 10%; // Spacing to main column
    }
    @media (@screen-below-large) {
      margin-top: 50px;
      max-width: var(--width-text-max);
    }
  }
}


