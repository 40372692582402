// x-media-fullscreen.less

/*
Fullscreen media (images and video) in article-type pages.
Sticky effect if figcaption present.
Transition for sticky effect if multiple in a row.

Script in x-media-wide.js
*/

#vrtx-structured-article,
#vrtx-structured-event {

  // Prevent 100vw images from adding horizontal scrollbar on windows
  overflow-x: hidden;

  // Fullscreen media (using image-center class)
  .vrtx-article-body .image-center {
    @media (@screen-large) {
      clear: both;
      margin: 40px 0;
      figure {
        position: relative;
        z-index: 1;
        margin: 0;

        // Video-component. Revert from relative to follow sticky behaviour
        .vrtx-background-video {
          position: static;
        }
      }
      img,
      video,
      figcaption {
        transition: opacity 1s;
      }
      img,
      video {
        max-width: none;
        width: 100vw;
        object-fit: cover;

        // Set img left pos at browser edge
        // Overflow viewport left spacing and content indent (below screen-oversized)
        // Spacing default 50, indent default 200
        margin-left: -250px;
      }
      figcaption {
        position: absolute;
        z-index: 2;
        left: calc(50vw - 30%);
        top: 50%;
        background: white;
        padding: 40px;
        margin: 0;
        font-size: inherit;
        color: inherit;
        width: 500px;
      }
    }
    @media (@screen-oversized) {
      img,
      video {
        // Set img left pos at browser edge
        // First move img left pos to center of content area (default 1400/2 = 700)
        // Content indented (default 200), so subtract that
        // Then subtract half of viewport width
        margin-left: calc(500px - 50vw);
      }
    }
  }
}

// Fullscreen media, escaping vortex admin preview
body:not(.vrtx-mode-preview) {
  &#vrtx-structured-article,
  &#vrtx-structured-event {
    .vrtx-article-body .image-center {
      // Img is full viewport height. Figure is 2x viewport height
      // Scroll distance is determined by figure height
      // When scrolled past image (= half of figure height), image is changed from fixed to absolute bottom of figure
      @media (@screen-large) {
        figure {
          height: 200vh; // Double viewport height for sticky scroll
        }
        img,
        video {
          height: 100vh;
        }
        &.sticky {
          img,
          video {
            position: fixed;
            top: 0;
            z-index: 1;
          }
          .pause-video {
            position: fixed;
            left: auto;
            z-index: 1;
          }
        }
        &.scrolled-past {
          img,
          video {
            position: absolute;
            bottom: 0;
          }
          figcaption {
            opacity: 0;
          }
        }
        // Allow chaining if directly following each other.
        &.has-caption + .has-caption {
          // Subtract media/screen height and margin between items.
          margin-top: calc(-100vh - 40px);
          img,
          video {
            opacity: 0;
          }
        }
        &.scrolled-past + .has-caption {
          img,
          video {
            opacity: 1;
          }
        }
      }
    }
  }
}
