// x-scroll-motion.less

/*
Adds motion to frontpage rows. Needs JS of same name.

NOTE: THIS IS TO BE CONSIDERED AS EXPERIMENTAL
As of december 2024 there has not been a viable case for good use of this.
Mostly it's a gimmick that should be avoided. So consider carefully.

- Note the use of prefers-reduced-motion
- Not recommended for rows that are above fold on load
- Some motion types may need reduced duration on small screen or they will be slow
- Can use transition-delay to adjust when motion starts

Two classes added to row (not box). "scroll-motion" + [motion-type], example:
"scroll-motion reveal-to-center"
"scroll-motion slide-from-right"

Motion-types:
- slide-from-right
- reveal-to-center
- image-reveal
*/

body:not(.vrtx-mode-edit) {
  @media (prefers-reduced-motion: no-preference) {
    .grid-container.scroll-motion {
      overflow: hidden;

      /*************************************************************
      Motion type: Slide-from-right

      Best for feeds and other low, horizontally oriented content
      **************************************************************/
      &.slide-from-right {
        .vrtx-frontpage-box {
          transform: translateX(90vw); // Show a bit, to make sure img loads
          transition: transform .75s ease-in-out;
        }
        &.entered-view {
          .vrtx-frontpage-box {
            transform: translateX(0);
          }
        }
      }

      /**************************************************************
      Motion-type: Reveal-to-center

      Note that before-element background must match local colors
      Best for img/box/link-special
      ***************************************************************/
      &.reveal-to-center {
        position: relative;
        &::before {
          content: "";
          background-color: white; // Match row background
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 100vw;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          transition: width .75s ease-in-out;
          // Reduced duration on small screen or movement will be slow
          @media (@screen-below-medium) {
            transition-duration: .5s;
          }
        }
        // For colored row background. Opaque needed.
        &.row-all-colored {
          &::before {
            background-color: var(--color-neutral-tint-opaque-95);
          }
        }
        &.entered-view {
          &::before {
            width: 0;
          }
        }
      }

      /**************************************************************
      Motion-type: Image-reveal

      Note that before-element background must match local colors
      Best for img-special
      **************************************************************/
      &.image-reveal {
        .vrtx-frontpage-box {
          .vrtx-frontpage-box-picture {
            position: relative;
            &::before {
              content: "";
              background-color: white; // Match row background
              position: absolute;
              z-index: 1;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              transition: width .75s ease-in-out;
              // Reduced duration on small screen or movement will be slow
              @media (@screen-below-medium) {
                transition-duration: .5s;
              }
            }
          }
          // Custom for img-special-right: Reverse reveal-direction
          &.img-special-right {
            .vrtx-frontpage-box-picture {
              &::before {
                left: auto;
                right: 0;
              }
            }
          }
        }
        // For colored row background. Opaque needed.
        &.row-all-colored {
          .vrtx-frontpage-box {
            .vrtx-frontpage-box-picture {
              &::before {
                background-color: var(--color-neutral-tint-opaque-95);
              }
            }
          }
        }
        &.entered-view {
          .vrtx-frontpage-box {
            .vrtx-frontpage-box-picture {
              &::before {
                width: 0;
              }
            }
          }
        }
      }
    }
  }
}

