// x-typography.less



body {
  font-family: helvetica, sans-serif;
  font-size: var(--font-size-base);

  /*
  Word breaking

  March 2024 update: Excluding hyphens, as they are too eager,
  and the limit-chars moderation only works in Chrome/Edge.
  Overflow-wrap will break words if no space, without hyphen unfortunately.

  https://www.epinova.no/en/folg-med/blog/2016/hyphenate-only-overflowing-words/
  https://github.com/w3c/csswg-drafts/issues/616
  */

  // -webkit-hyphens: auto;
  // hyphens: auto;
  overflow-wrap: anywhere;

  /*
  Limit hyphenation to words of:
  <minimum total chars> <minimum chars before break> <minimum chars after break>
  Minimum total chars set as high as possible (best guess).
  Minimum chars before and after can't be too high, or risk not breaking where gramatically correct.
  Only supported in Chrome (incl Edge) on desktop as of Jan 2024. (Not iOS. Chrome on iOS = webkit).
  */

  // hyphenate-limit-chars: 16 4 4;
}

// Switch font to arial if firefox
@-moz-document url-prefix() {
  body {
    font-family: arial, sans-serif;
  }
}



// Word-break exclusions
// @media (@screen-large) {
//   h1,
//   .vrtx-introduction,
//   .vrtx-article-body,
//   .box-special,
//   .link-special
//   {
//     -webkit-hyphens: initial;
//     hyphens: initial;
//   }
// }

// Tables excluded for all viewport widths, or will become too squeezed
table {
  // -webkit-hyphens: initial;
  // hyphens: initial;
  overflow-wrap: initial; // Needs this or will break too much
}



// Sub, sup
sub,
sup {
  vertical-align: baseline;
  position: relative;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}



// Preformatted
pre {
  background-color: var(--color-neutral-tint-95);
  border: 2px dashed var(--color-neutral-tint-80);
  padding: 20px;
  margin: 0 0 25px 0;
  font-family: monospace;
  font-size: var(--font-size-small);
  overflow: auto; // Will scroll sideways if necessary
}



// Blockquote
blockquote {
  margin: 0;
  &::before {
    content: "“";
    display: block;
    font-size: 4em; // em to scale with parent size
    line-height: 1;
    height: .5em;
  }
  p:first-child {
    font-style: italic;
    font-size: var(--font-size-large-10);
  }
}
cite {
  font-style: normal;
}



// P, ul, ol, dl
p,
ul,
ol,
dl {
  margin: 0 0 25px 0;
}
ul,
ol {
  padding-left: 30px;
}
ul ul,
ol ol {
  margin-bottom: 0;
}
dt {
  font-weight: bold;
}
dd {
  margin: 0;
}



// Headings
h1 {
  font-size: var(--font-size-large-40);
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 25px;
  @media (@screen-below-medium) {
    font-size: var(--font-size-large-30);
    line-height: 1.3;
  }
  // If chosen option to hide on frontpage
  &.hidden {
    .invisible;
  }
}
h2,
.mixin-h2 {
  font-weight: bold;
  font-size: var(--font-size-large-30);
  line-height: 1.3;
  margin: 0 0 25px 0;
  @media (@screen-below-medium) {
    font-size: var(--font-size-large-20);
  }
}
h3,
.mixin-h3 {
  font-weight: bold;
  font-size: var(--font-size-large-10);
  line-height: 1.3;
  margin: 0 0 15px 0;
}
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}



// Introduction
.vrtx-introduction {
  margin-bottom: 50px;
  font-size: var(--font-size-large-10);
  @media (@screen-medium) {
    font-size: var(--font-size-large-20);
  }
}


