// x-consent-subtle.less

/*
Optional, subtle style for uio cookie banner.
Requires script of same name.
Primarily overrides the first dialog of the cookie-banner, not the settings-dialog.

Some !importants are used due to code structure.
Need to override both uio inheritance and possible local page settings,
such as if an h1 is styled based on page ID.
*/


// Hide overlay for first dialog
.consent-overlay {
  display: none;
}
// Display overlay for settings dialog
body.consent-settings-opened {
  .consent-overlay {
    display: block;
  }
}
// Hide language selector in first dialog (can change lang in header)
.consent .consent-inner .consent-lang {
  display: none;
}
// First dialog
.consent {
  width: 100%;
  left: 0;
  top: auto !important;
  bottom: 0;
  height: auto !important;
  border: none;
  background-color: #eee;
  box-shadow: 0 0 10px #0004;

  // Title
  h1 {
    font-size: var(--font-size-large-10) !important;
    font-weight: bold !important;
    line-height: 1.3 !important;
    padding: 0 !important;
    margin: 0 !important;
    @media (@screen-below-medium) {
      font-size: var(--font-size-large-05) !important;
    }
  }
  // Inner
  .consent-inner {
    display: grid;
    max-width: 1500px;
    margin: 0 auto;
    padding: 25px 50px;

    // Main text
    .consent-main-intro-text {
      line-height: inherit !important;
    }
    // Buttons
    .consent-main-buttons {
      padding: 5px 0;
      .button-large {
        min-width: 0;
      }
    }
    // Customize-link (opens settings)
    .consent-customize {
      font-size: var(--font-size-small);
    }
    @media (@screen-medium) {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto auto 1fr;
      gap: 10px 40px;

      // Main text
      .consent-main-intro-text {
        grid-column: 1;
        grid-row: 2/-1;
      }
      // Buttons
      .consent-main-buttons {
        grid-column: 2;
        grid-row: 2;
      }
      // Consent-customize container
      p:has(.consent-customize) {
        grid-column: 2;
        grid-row: 3;
        line-height: inherit;
      }
    }
    @media (@screen-below-medium) {
      padding: 20px 15px;
    }
  }
}
// Settings dialog
// .consent-settings {
//   .consent-settings-inner {
//     .consent-settings-marketing {
//     }
//   }
// }