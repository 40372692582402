// x-viewport.less


// Global outer max-width and side-spacing
#hidnav,
#head-admin-menu,
#head,
.sidebar-menu-inner-wrapper,
.left-menu-wrapper,

// Frontpage specific
#vrtx-frontpage #left-main,
#vrtx-frontpage #breadcrumbs,
#vrtx-frontpage h1,
#vrtx-frontpage .vrtx-introduction,
.row,

// All except frontpage
body:not(#vrtx-frontpage) #main,

// Mixin for this behavior
.mixin-page-wrapper

{
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  max-width: var(--width-total-max);
  @media (@screen-below-medium) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
