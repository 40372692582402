// x-menus.less


// Sidebar-menu-wrapper (main menu)
.sidebar-menu-wrapper {
  position: relative; // For z-level higher than #main content
  overflow: hidden;
  transition: height .5s;
  .sidebar-menu {
    padding-bottom: 50px;
    // Transition moved from sidebar-global-menu Oct. 2024 by Kjetil
    // To enable transition of background-color etc. on this element
    // Same duration as menu-wrapper transition
    transition: all .5s;
  }
  // Search form inside menu, default not in use
  .sidebar-search {
    display: none;
    padding-top: 10px; // Prevents input focus overflow cutoff (can be less)
    margin-bottom: 40px;
  }
  .sidebar-global-menu {
    padding: 25px 0;
    margin: 0 auto;
    border: 1px solid;
    border-width: 1px 0;
    max-width: 600px;
    @media (@screen-medium) {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    display: block;
    text-decoration: none;
    padding: 10px 30px 10px 0;
    transition: all .3s, font-size 0s;
    &:hover {
      text-decoration: none;
      background: var(--color-neutral-tint-95);
      padding-right: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  // Main menu ul
  .vrtx-tab-menu {
    @media (@screen-medium) {
      flex-grow: 1;
      a {
        font-size: var(--font-size-large-10);
      }
    }
  }
  #supplementary-menu {
    display: none; // Default hidden
    @media (@screen-below-medium) {
      margin-top: 20px;
      a {
        font-size: var(--font-size-small);
      }
    }
  }
}
// Menu opened/closed
body:not(.sidebar-menu-wrapper-visible) {
  .sidebar-menu-wrapper {
    visibility: hidden;
    height: 0;
  }
}
body.sidebar-menu-wrapper-visible {
  .sidebar-menu-wrapper {
    // Variable set by js to enable height transition
    height: var(--open);
  }
}



// Global-menu. Contains language & other global stuff
#global-menu {
  ul {
    list-style: none;
    padding: 0;
  }
  // Could maybe remove this, but targetting specific for now, due to unknown usage
  .sidebar-services-language-menu {
    margin: 0;
    .language {
      a {
        display: none;
      }
    }
  }
}



// Global sub menu
#global-sub-menu {
  .inner {
    .mixin-page-wrapper;
  }
  .vrtx-subfolder-menu {
    font-size: var(--font-size-small);
    color: var(--color-neutral-tint-25);
    padding-bottom: 20px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      &,
      li {
        display: inline;
        margin-right: 10px;
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.the-frontpage #global-sub-menu {
  display: none;
}



// Left menu (old)
#left-menu-same-level-folders {
  // If hidden in page settings
  &.hidden {
    display: none;
  }
  font-size: var(--font-size-small);
  color: var(--color-neutral-tint-25);
  margin-bottom: 20px;

  // Title
  #left-menu-title {
    display: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    &,
    li {
      display: inline;
    }
    .vrtx-ancestor {
      display: none;
    }
    .vrtx-parent {
      > a::after {
        content: ":";
      }
    }
    .vrtx-marked {
      font-weight: bold;
      color: black;
    }
  }
  a {
    margin-right: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}



// Breadcrumbs
#breadcrumbs {
  font-size: var(--font-size-tiny);

  // Wrapper
  #vrtx-breadcrumb-wrapper {
    margin-bottom: 20px;
  }
  // Breadcrumbs separator and active crumb (current page)
  .vrtx-breadcrumb-delimiter,
  .vrtx-breadcrumb-active {
    display: none;
  }
  // Each crumb
  span {
    display: inline-flex;

    // Arrow
    &::after {
      content: "";
      flex-shrink: 0;
      background: url("../images/chevron.svg") no-repeat center;
      width: 15px;
      margin-left: 5px;
      opacity: .6;
    }
    // On small screen show only parent level
    @media (@screen-below-medium) {
      &:not(.vrtx-breadcrumb-before-active) {
        display: none;
      }
    }
  }
  a {
    color: var(--color-neutral-tint-25);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}



// Subfolder-menu (automatic on list pages and can be manually inserted)
// Automatic variant inside #vrtx-collections (usually hidden)
// Including .main to exclude global sub menu from inheriting styles
.main .vrtx-subfolder-menu {
  background: var(--color-neutral-tint-95);
  padding: 15px;
  margin-bottom: 40px;
  column-width: 250px;
  column-count: auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    // Indent sub-levels
    ul {
      padding-left: 30px;
    }
  }
  .menu-title {
    column-span: all;
    font-weight: bold;
    margin-bottom: 5px;
  }
  a {
    display: inline-flex;
    gap: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: "";
      flex-shrink: 0;
      background: url("../images/arrow.svg") no-repeat center;
      background-size: contain;
      width: 18px;
      height: 1.5em; // Match with line-height to center on text
    }
  }
}
// In colored row
.main .row-all-colored .vrtx-subfolder-menu {
  // Changed 26. aug 2024 by Kjetil. Logic was weird. Now logic is same as item-special etc.
  background: white;
  // background: none;
  // padding: 0;
}



// Navigation-links
.navigation-links {
  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--space);

    // Two columns is default
    @media (@screen-medium) {
      grid-template-columns: 1fr 1fr;
    }
  }
  li {
    display: grid; // Make links fill container
    a {
      .mixin-button-link;
      background-color: var(--color-neutral-tint-95);
      border-color: transparent;
    }
    // Strong used for heading
    strong {
      display: block;
      margin-bottom: 5px;
    }
    // Disable manual line-breaks
    br {
      display: none;
    }
  }
  &.navigation-links-three-columns {
    ul,
    ol {
      @media (@screen-large) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  &.navigation-links-one-column {
    ul,
    ol {
      @media (@screen-medium) {
        grid-template-columns: none;
        max-width: var(--width-text-max);
      }
    }
  }
  &.numbered {
    ul, // Supporting ul, but should be ol
    ol {
      counter-reset: numbered-nav-links;
    }
    li {
      a {
        counter-increment: numbered-nav-links;
        position: relative;
        padding-left: 6rem;
        &::before {
          content: counter(numbered-nav-links);
          position: absolute;
          left: 20px;
          font-size: var(--font-size-large-20);
          line-height: .9;
        }
      }
    }
  }
}
// In colored row
.row-all-colored .navigation-links {
  li a {
    background-color: white;
  }
}



// Subfolder-menu with navigation-links class
.main .navigation-links .vrtx-subfolder-menu {
  background: none;
  padding: 0;
  columns: initial;
  .menu-title {
    .mixin-h3;
  }
  li {
    overflow: initial;
  }
  a {
    &::before {
      display: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
}



// Toc
// Header (is span in manually inserted variant, is h2 in study plan)
span.vrtx-toc-header {
  font-weight: bold;
}
#toc {
  margin-bottom: 50px;
}
// Hide child ul if only one level
.vrtx-toc-only-one-level {
  #toc {
    ul {
      ul {
        display: none;
      }
    }
  }
}


