// x-footer.less


#footer-wrapper {
  margin-bottom: 0;
  padding: 50px 0;
  background-color: var(--color-nearly-black);
  color: white;
  font-size: var(--font-size-small);
  .row,
  .footer-content {
    display: grid;
    gap: 40px;
  }
  .row {
    @media (@screen-medium) {
      grid-template-columns: 1fr auto;
    }
  }
  .footer-content {
    @media (@screen-large) {
      grid-auto-flow: column;
    }
  }
  .menu-label {
    .mixin-h2;
  }
  h2,
  .menu-label {
    font-size: var(--font-size-base);
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
  h2:not(:first-child) {
    margin-top: 25px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  img {
    margin: 0;
  }

  // SoMe icons
  a[href*=twitter]::before,
  a[href*=facebook]::before {
    content: "";
    background: no-repeat center;
    background-size: contain;
    padding: 10px;
    margin-right: 10px;
  }
  a[href*=twitter]::before {
    background-image: url("../images/twitter-white.svg");
  }
  a[href*=facebook]::before {
    background-image: url("../images/facebook-white.svg");
  }



  // Privacy notification
  // Needs x-privacy-notification.js
  // Primitive cookie banner used by some sites. See js file for details.
  #privacy-notification {

    // Default hidden, pending js conditions
    &:not(.visible) {
      display: none;
    }
    position: fixed;
    z-index: 100; // Above all
    left: 0;
    bottom: 0;
    background-color: black;
    width: 100%;
    padding: 15px 50px;
    @media (@screen-below-medium) {
      padding: 15px;
    }
    > div {
      width: fit-content;
      margin: 0 auto;
    }
    p, button {
      margin: 0 10px 10px 0;
    }
    p {
      display: inline-block;
    }
    button {
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }
    // Container for use if multiple buttons
    .buttons {
      display: inline-block;
    }
  }
}



// UiO footer logo area
#uio-footer-logo {
  background-color: var(--color-nearly-black);
  padding: 25px 0;
  margin: 0;
  a {
    display: block;
    background: url("../images/uio-logo-white.svg") no-repeat left center;
    background-size: contain;
    width: 250px;
    height: 60px;
  }
}
html[lang=en] #uio-footer-logo {
  a {
    background-image: url("../images/uio-logo-en-white.svg");
  }
}


