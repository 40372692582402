// aho local.less

@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

:root {
  // X-overrides
  --radius: 30px;
  --border: 2px solid;

  // Local
  --color-background-main: #F4F0E7;
  --color-midnight: #00142C;
  --color-orange: #EF4300; // Contrast to white only for large text

  // Secondary colors not in use. Kept here for potential future use.
  // --color-yellow: #E3EB00;
  // --color-blue: #0F6CB3;
  // --color-green: #9E9000;
  // --color-purple: #C593AE;

  --color-dark-30: #0009; // Contrast 5+
  --color-dark-50: #0006;
  --color-dark-80: #0003;
  --color-dark-90: #0001;

  --font-size-large-50: 6rem;
}



// Font: Untitled Sans
@font-face {
  font-family: "UntitledSans";
  src: url("../fonts/untitled-sans-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'UntitledSans';
  src: url("../fonts/untitled-sans-regular-italic.woff2") format('woff2');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "UntitledSans";
  src: url("../fonts/untitled-sans-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'UntitledSans';
  src: url("../fonts/untitled-sans-bold-italic.woff2") format('woff2');
  font-weight: bold;
  font-style: italic;
}



// (i) symbol used for some info/facts boxes. Container needs position relative.
.mixin-info-symbol {
  content: "i";
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: var(--font-size-large-10);
  background: var(--color-background-main);
  color: var(--color-midnight);
}



/************************************************************
General and misc short rules
************************************************************/

body {
  background-color: var(--color-background-main);
  font-family: UntitledSans, sans-serif;
}

#global-sub-menu {
  display: none; // Remove footer menu
}

#vrtx-feed-link,
.vrtx-feed-link,
.vrtx-social-components {
  display: none;
}

.main .vrtx-subfolder-menu {
  background-color: white;
  padding: 30px;
}

.main .vrtx-facts-container {
  background-color: white;
  border-radius: var(--radius);
  h3 {
    font-weight: bold;
  }
}

.vrtx-frontpage-box {
  .vrtx-facts-container {
    padding: 30px;
    max-width: var(--width-text-max);
  }
}

.link-list {
  li {
    margin-bottom: 10px;
    ul {
      margin-top: 10px;
    }
  }
}

.vrtx-paging-wrapper {
  display: flex;
  flex-wrap: wrap;
  a {
    display: inline-flex;
    min-height: 44px;
    min-width: 44px;
    align-items: center;
    justify-content: center;
    border: var(--border);
    &:hover {
      background-color: white;
    }
  }
}



/************************************************************
Typography
************************************************************/

h1,
h2,
.mixin-h2,
h3,
.mixin-h3 {
  font-weight: normal;
}

h1 {
  color: var(--color-orange);
  @media (@screen-large) {
    font-size: 8rem;
    line-height: 1.1;
    margin-bottom: 40px;
  }
  @media (@screen-below-large) {
    line-height: 1.2;
  }
}

.vrtx-introduction {
  color: var(--color-orange);
  @media (@screen-medium) {
    line-height: 1.4;
  }
  * {
    max-width: var(--width-soft-max);
  }
}

blockquote {
  p:first-child {
    font-style: normal;
  }
}



/************************************************************
Links
************************************************************/

a,
.mixin-underline {
  text-decoration-thickness: .06em;
  text-underline-offset: .1em;
}
.mixin-underline {
  text-decoration-line: underline;
}

// Animated underline (requires link to be display inline)
.mixin-fancy-underline-grow,
.mixin-fancy-underline-grow-hover {
  @media (pointer: fine) { // Enable only for mouse pointer and similar
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    transition: all .5s;
  }
}
.mixin-fancy-underline-grow {
  background-position: left bottom;
  background-size: 0 2px;
}
.mixin-fancy-underline-grow-hover {
  background-size: 100% 2px;
}

// Markers for links to other language and external. Added with JS
a {
  // Language marker. Span added with JS
  .language-marker {
    font-size: .8em;
  }
  // External link icon. Inline SVG added with JS
  .external-link-svg {
    position: relative;
    top: .05em; // Tweak vertical pos.
  }
  // Hide when img in link
  img {
    & ~ .language-marker,
    & ~ .external-link-svg {
      display: none;
    }
  }
}


/************************************************************
Buttons and inputs
************************************************************/

.mixin-button-link {
  padding-left: 25px;
  border: var(--border);
  &::after {
    background-size: 24px auto;
  }
  &:hover {
    &::after {
      right: 10px;
    }
  }
}

// Button heavy
.button-heavy,
.button-heavy-loud {
  .mixin-button-link;
  font-size: var(--font-size-large-10);
  line-height: 1.3;
  padding-top: 13px;
  padding-bottom: 13px;
  &::after {
    height: 1.3em; // Match line-height
    top: 13px; // Match padding-top
  }
  .language-marker {
    // For wcag contrast
    font-size: inherit;
    font-variant-caps: all-small-caps;
  }
}
.button-heavy {
  color: var(--color-orange);
  &::after {
    background-image: url("../images/arrow-orange.svg");
  }
}
.button-heavy-loud {
  background-color: var(--color-orange);
  border-color: transparent;
  color: white;
  &::after {
    background-image: url("../images/arrow-white.svg");
  }
}

// Experimental: If multiple button-links in a p, add spacing to them
// This to allow a row of buttons without the need for special class
p:has([class^=button] + [class^=button]) {
  [class^=button] {
    margin: 0 15px 15px 0;
  }
}

// Native elements
input,
button,
select {
  border: var(--border);
  padding: 5px 15px;
  accent-color: var(--color-orange);
  &:hover {
    background-color: white;
  }
}
select {
  background-position: center right 15px;
  background-size: 16px auto;
  padding-right: 40px;
  // Custom focus. Mainly for Student projects. Safari outline ugly.
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-background-main),
                0 0 0 4px var(--color-orange);
  }
}



/************************************************************
Header, menu, search, language
************************************************************/

// Header
// Note that main menu top position (margin) must match header height:
// Calculated from min-height on #header + padding on #head-wrapper
#header {
  align-items: start;
  column-gap: 20px;
  min-height: 90px; // Match menu top position
  padding-top: 20px;
  @media (@screen-below-medium) {
    min-height: 60px; // Match menu top position
    padding-top: 5px;
  }
  .logo {
    position: relative;
    background-image: url("../images/logo-full-horizontal-orange-no.svg");
    background-size: auto 100%;
    width: 215px;
    height: 54px;
    margin-right: 0;
    @media (@screen-large) {
      width: 520px;
      // Element covers logo text and makes it visible on hover
      &::after {
        content: "";
        position: absolute;
        left: 215px; // Match logo width
        top: 0;
        background-color: var(--color-background-main);
        height: 100%;
        width: 300px;
        transition: opacity .5s;
      }
      &:hover {
        &::after {
          opacity: 0;
          transition-delay: .5s;
        }
      }
    }
    &:focus {
      outline-offset: 4px;
      transition: none;
    }
  }
}
// English logo
html[lang=en] #header {
  .logo {
    background-image: url("../images/logo-full-horizontal-orange-en.svg");
  }
}

#header-tools {
  grid-template-columns: auto auto;
  gap: 0;
  margin-left: auto;

  #header-language {
    gap: 2px;
    color: var(--color-orange);
    font-size: 1.9rem; // Min. with bold for wcag orange
    font-weight: bold;
    transition: all .5s;
    @media (@screen-medium) {
      margin-right: 40px;
    }
    @media (@screen-below-medium) {
      position: absolute;
      right: -5px;
      top: 85px;
    }
    > a,
    > span {
      padding: 0 10px;
      border: none;
      border-radius: var(--radius);
      font-size: inherit;
    }
    > a {
      transition: all .3s;
      &:hover {
        text-decoration: none;
      }
    }
    > a:hover,
    > span {
      background-color: var(--color-orange);
      color: var(--color-background-main);
    }
  }
  .toggle-search {
    display: none;
  }
  .sidebar-menu-toggle {
    position: relative;
    width: 52px;
    height: 49px;
    border-radius: 0;
    &::before,
    &::after,
    span::after { // Using text span for middle burger part
      content: "";
      background-color: var(--color-orange);
      position: absolute;
      width: 52px;
      height: 13px;
      transition: all .3s;
    }
    &::before,
    &::after {
      transform-origin: right;
    }
    &::before {
      background-image: none;
      top: 0;
      margin: 0;
    }
    &::after {
      bottom: 0;
    }
    span {
      .invisible; // Text hidden
      // Middle burger part
      &::after {
        left: 100000px; // Reverse invisible position
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &[aria-expanded=true] {
      &::before {
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
      }
      span::after {
        opacity: 0;
      }
    }
  }
}



/*
Main menu

Note the use of margin for top position. The margin must match header height.
Header height is stable and calculated from min-height on #header and padding on #head-wrapper
This to avoid height differences due to admin-menu, crisis banner etc.
*/
#main-menu {
  position: absolute;
  right: 0;
  z-index: 9;
  height: auto;
  margin-top: -130px; // Match header height
  @media (@screen-below-medium) {
    margin-top: -80px; // Match header height
    font-size: 2.2rem;
  }
  a {
    .mixin-fancy-underline-grow;
    background-position: left bottom 10px;
    width: fit-content;
    padding: 10px 0;
    &:hover {
      .mixin-fancy-underline-grow-hover;
      background-color: transparent;
    }
    // English menu item language indicator span (added with JS)
    span {
      font-size: .75em;
    }
  }
  .sidebar-menu {
    background-color: white;
    padding-top: 160px; // Determines spacing above search-form
    border-bottom-left-radius: var(--radius);
    max-width: 90vw;
    transition: all .5s;
    @media (@screen-oversized) {
      padding-right: calc((100vw - 1500px) / 2);
    }
  }
  .search-form {
    display: block;
    @media (@screen-below-medium) {
      margin-bottom: 30px;
    }
    form {
      position: relative;
      border-bottom: 2px solid;
      max-width: none;
      // Input chevron
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 20px;
        width: 18px;
        height: 18px;
        background: url("../images/chevron.svg") no-repeat left center;
        background-size: contain;
        opacity: 0;
        transition: opacity .3s;
      }
      &:has(input:focus) {
        &::before {
          opacity: 1;
        }
      }
      input,
      button {
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      input {
        padding-right: 24px;
        padding-left: 0;
        background: none;
        transition: padding .3s;
        &::placeholder {
          font-size: inherit;
          color: var(--color-dark-30);
        }
        &:focus {
          outline: none;
          padding-right: 0; // Move padding to left, for icon
          padding-left: 24px;
        }
        // Override background color from autocomplete
        &:autofill {
          box-shadow: inset 0 0 0 50px white;
        }
      }
      button {
        padding-right: 20px;
        padding-left: 20px;
        background-image: url("../images/search.svg");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 0;
        text-indent: -100000px; // Hide except for screen-reader
        transition: all .3s;
        &:focus {
          outline: none;
        }
        &:focus,
        &:hover {
          background-image: url("../images/search-white.svg");
          background-color: black;
        }
      }
    }
  }
  .sidebar-global-menu {
    border: none;
    padding-top: 0;
    max-width: none;
    transition: none;
  }
  // Hide front page (= parent-folder on sub level, such as /english)
  .root-folder,
  .parent-folder {
    display: none;
  }
  #supplementary-menu {
    display: block;
    a {
      @media (@screen-below-medium) {
        font-size: var(--font-size-base);
      }
    }
  }
}

// Admin modes
body:not(.vrtx-mode-edit, .vrtx-mode-preview) {
  #main-menu {
    .sidebar-menu {
      min-height: calc(100vh + 20px); // Add some to push radius outside
    }
  }
}

// When menu not visible
body:not(.sidebar-menu-wrapper-visible) {
  #header-tools {
    #header-language {
      @media (@screen-below-medium) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  #main-menu {
    .sidebar-menu {
      transform: translateX(100%);
    }
    .sidebar-menu-inner-wrapper {
      &:focus-within {
        display: none; // Prevent focus during closing transition
      }
    }
  }
}

// Hwen menu visible
body.sidebar-menu-wrapper-visible {
  #header {
    .logo {
      @media (@screen-large) {
        &::after {
          opacity: 0;
        }
      }
    }
  }
}



/************************************************************
Breadcrumbs
************************************************************/

#breadcrumbs {
  #vrtx-breadcrumb-wrapper {
    @media (@screen-medium) {
      margin-bottom: 30px;
    }
    @media (@screen-below-medium) {
      margin-top: 5px;
    }
  }
}
body.the-frontpage #breadcrumbs {
  display: none;
}



/************************************************************
Frontpage logo scale effect

Variables added as body style properties by js:
--logo-scale-factor
--logo-scaled-height
************************************************************/

body.the-frontpage {
  // Introduction (not directly tied to scale-effect)
  .vrtx-introduction {
    margin-top: 10px;
  }
  // Scale-down effect on tablet and above, unless prefers reduced motion
  @media (@screen-medium) and (prefers-reduced-motion: no-preference) {
    #header .logo {
      // Logo hidden until js loaded to prevent flash of small logo before scale
      &:not(.ready) {
        visibility: hidden;
      }
      // Prevent logo hover effect until scaled
      &.unscaled {
        pointer-events: none;
        width: 215px;
        &::after {
          display: none;
        }
      }
      // Scale logo to full width and move it down a fixed amount
      // Y-position divided by scale-factor to maintain fixed value
      transform: scale(var(--logo-scale-factor)) translateY(calc(150px / var(--logo-scale-factor)));
      transform-origin: left top;
    }
    #vrtx-content {
      // Move page content down equal to logo scaled height + a bit
      transform: translateY(calc(var(--logo-scaled-height) + 100px));
    }
    // Scale down
    &.logo-scale-down {
      #header .logo.unscaled,
      #vrtx-content {
        transform: translateY(0);
        transition: transform 1.5s;
      }
      #header .logo {
        transform: scale(1);
      }
    }
  }
}



/************************************************************
Footer
************************************************************/

#footer-wrapper {
  background-color: var(--color-orange);
  padding: 20px 0 100px;
  font-size: var(--font-size-base);
  color: var(--color-midnight);

  .row {
    align-items: start;
    row-gap: 60px;
    @media (@screen-below-large) {
      grid-template-columns: none;
    }
    h2,
    .menu-label {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0;
    }
  }
  .footer-content {
    row-gap: 60px;
    @media (@screen-large) {
      grid-auto-flow: unset;
      grid-template-columns: auto auto;
      justify-content: space-between;
      column-gap: 80px; // Space for loud-links arrows
    }
  }
  .logo {
    grid-column: 1/-1;
    background: url("../images/logo-full-horizontal-black-no.svg") no-repeat;
    background-size: contain;
    height: 54px; // Match header logo
    max-width: 520px; // Prevent link going too wide
    @media (@screen-below-medium) {
      background-image: url("../images/logo-full-vertical-black-no.svg");
      height: 118px;
      max-width: 290px;
    }
    span {
      .invisible; // Hide logo text
    }
  }
  .loud-text,
  .loud-links {
    font-size: var(--font-size-large-50);
    line-height: 1.2;
    @media (@screen-below-large) {
      font-size: var(--font-size-large-30);
    }
  }
  .loud-links {
    li {
      margin-bottom: 20px;
    }
    a {
      text-decoration: none;
      &::after {
        content: "";
        background: url("../images/arrow-large-midnight.svg") no-repeat center;
        padding-right: 40px; // Arrow size
        position: relative;
        top: 6px;
        left: .25em;
        transition: all .3s;
        @media (@screen-below-large) {
          background-size: 25px auto;
          padding-right: 25px; // Arrow size
          top: 4px;
        }
      }
      &:hover {
        &::after {
          left: .5em;
        }
      }
    }
  }
  .vrtx-social-list {
    margin: 30px 0 0 -12px;
    li {
      margin: 0 10px 10px 0;
    }
    a {
      mix-blend-mode: multiply;
      filter: invert(1);
      opacity: 1;
      border-radius: 0;
      border-bottom: 1px solid transparent;
      transition: none;
      &:hover {
        border-bottom-color: white;
      }
      &.facebook {
        position: relative;
        top: -2px; // Baseline
      }
    }
  }
  .responsible-login {
    @media (@screen-large) {
      margin-top: 114px; // Align (footer-content row-gap + logo height)
    }
    @media (@screen-below-medium) {
      font-size: var(--font-size-small);
    }
  }
  .vrtx-dropdown-link::after {
    background-image: url("../images/arrow-triangle.svg");
  }
}
// English logo
html[lang=en] #footer-wrapper {
  .logo {
    background-image: url("../images/logo-full-horizontal-black-en.svg");
    @media (@screen-below-medium) {
      background-image: url("../images/logo-full-vertical-black-en.svg");
    }
  }
}
// For-ansatte footer
body.for-ansatte #footer-wrapper {
  padding-top: 30px;
  .responsible-login {
    margin: 0;
  }
}



/************************************************************
Various frontpage row classes
************************************************************/

.grid-container {
  // Background-color alternating disabled
  &.row-all-colored {
    background-color: transparent;
    margin-bottom: calc(3 * var(--space));
    padding: 0;
  }
  &.media-wide {
    &.row-all-colored {
      margin-bottom: calc(2 * var(--space));
    }
    img,
    video {
      @media (@screen-large) {
        height: 80vh;
      }
    }
    .vrtx-box-content {
      @media (@screen-large) {
        bottom: 80px;
        border-radius: var(--radius);
        padding: 40px;
        width: 550px;
        max-width: 50%;
      }
    }
  }
  // Match with 3-column layout, for when last column has low width requirements
  &.two-thirds-row {
    .column-wrapper {
      @media (@screen-large) {
        grid-template-columns: 1fr 1fr 1fr; // Triple to balance with triple rows
        .vrtx-frontpage-box {
          &:first-child {
            grid-column: span 2;
          }
        }
      }
    }
  }
}



/************************************************************
Navigation-links
************************************************************/

#main .navigation-links {
  ul,
  ol {
   gap: 15px;
  }
  li {
    a {
      background-color: transparent;
      border: var(--border);
    }
    strong {
      margin: 0;
    }
  }
  &.navigation-links-four-columns {
    ul,
    ol {
      @media (@screen-large) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  &.heavy {
    li {
      a {
        .button-heavy;
        // If has heading, increase vertical space, reduce right space and move arrow
        &:has(strong:first-child) {
          padding-top: 20px;
          padding-bottom: 25px;
          padding-right: 40px;
          // Place arrow inline after text, for more space
          &::after {
            top: auto;
            right: auto;
            margin-left: 10px;
          }
          &:hover {
            &::after {
              right: auto;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  &.numbered {
    li {
      a {
        min-height: 90px;
        &::before {
          font-weight: bold;
          font-size: var(--font-size-large-30);
        }
        @media (@screen-large) {
          padding-left: 8rem;
          &::before {
            font-size: var(--font-size-large-50);
          }
        }
      }
    }
  }
  &.heavy.numbered {
    li {
      a {
        padding-left: 6rem;

        @media (@screen-large) {
          padding-left: 9rem;
          min-height: 100px;
          &::before {
            font-size: 7rem;
          }
        }
      }
    }
  }
}



/************************************************************
Item-special
************************************************************/

.vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right,
  &.box-special {
    background-color: white;
    .vrtx-box-content {
      @media (@screen-below-medium) {
        padding: 30px 25px;
      }
    }
  }
  &.img-special-left,
  &.img-special-right {
    &.airy {
      background-color: transparent;
      .vrtx-box-content {
        @media (@screen-below-medium) {
          padding: 30px 0 0 0;
        }
      }
    }
  }
  &.img-special-left {
    &.airy {
      .vrtx-box-content {
        @media (@screen-medium) {
          padding-right: 0;
        }
      }
    }
  }
  &.img-special-right {
    &.airy {
      .vrtx-box-content {
        @media (@screen-medium) {
          padding-left: 0;
        }
      }
    }
  }
  &.box-special,
  &.link-special {
    border-radius: var(--radius);
    text-wrap: pretty; // New feature. Supports Chrome only as of dec. 2024
  }
  &.link-special {
    color: var(--color-orange);
    border: var(--border);
    @media (@screen-below-medium) {
      padding-left: 25px;
      min-height: 120px; // To be bigger than nav-links and buttons
      display: grid;
      align-items: center;
    }
    .vrtx-box-content {
      font-weight: normal;
      @media (@screen-medium) {
        line-height: 1.1;
      }
    }
    a {
      // Arrow
      &::after {
        background-image: url("../images/arrow-orange.svg");
        background-color: transparent;
        background-size: .75em auto;
        width: .75em;
        height: 1.2em;
        margin-top: 0;
        margin-left: .25em;
        transition: all .3s;
      }
      &:hover {
        text-decoration: none;
          &::after {
            margin-left: .5em;
        }
      }
    }
  }
}



/************************************************************
Number-facts
************************************************************/

.vrtx-frontpage-box {
  &.number-facts {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 40px;
      list-style: none;
      padding: 0;
      margin: 40px 0;
    }
    li {
      border-top: 1px solid;
      padding-top: 20px;
      // Number
      strong {
        display: block;
        font-size: var(--font-size-large-50);
        line-height: 1;
        margin-bottom: 15px;
        @media (@screen-below-medium) {
          font-size: var(--font-size-large-40);
        }
      }
    }
  }
}



/************************************************************
Library-search
************************************************************/

.vrtx-frontpage-box {
  &.library-search {
    background-color: white;
    border-radius: var(--radius);
    padding: 7.5%;
    fieldset {
      display: flex;
      max-width: var(--width-text-max);
      margin-bottom: 45px;
      .text-field,
      button {
        border-width: 0 0 2px 0;
      }
      .text-field {
        width: 100%;
        padding-left: 0;
        min-height: 60px;
        transition: all .3s;
        @media (@screen-medium) {
          font-size: var(--font-size-large-10);
        }
        &::placeholder {
          color: var(--color-dark-30);
        }
        &:focus {
          outline: none;
          background: url("../images/chevron.svg") no-repeat left center;
          background-size: 10px auto;
          padding-left: 24px;
        }
      }
      button {
        background: url("../images/search.svg") no-repeat center;
        flex-shrink: 0;
        border-radius: 0;
        padding: 5px 20px;
        transition: all .3s;
        &:focus {
          outline: none;
        }
        &:focus,
        &:hover {
          background-image: url("../images/search-white.svg");
          background-color: black;
        }
        span {
          .invisible; // Hide, except for screen-reader
        }
      }
    }
    // Links
    a {
      display: inline-block;
      margin: 0 20px 10px 0;
    }
  }
}



/******************************************************************
Timeline
Prev/next buttons moved to top, so some logic to handle scenarios:
- If has row-heading
- If has box-heading
- If has no headings
******************************************************************/

#main .row:has(.timeline) {

  // Row- or box-heading
  // Add right spacing for prev/next buttons
  > h2,
  .vrtx-frontpage-box > h2 {
    padding-right: 100px;
  }

  // Conditionals for heading presence
  // To accomodate prev/next buttons

  // Default top space (assuming no heading present)
  .vrtx-frontpage-box {
    padding-top: 70px; // Add top space for prev-next-wrapper
  }
  // If has row-heading
  &:has(> h2) {
    .prev-next-wrapper {
      top: -65px; // Move prev-next-wrapper up to align with heading
    }
    .vrtx-frontpage-box {
      padding-top: 0; // Remove added top space
    }
  }
  // If has box-heading
  .vrtx-frontpage-box:has(> h2) {
    padding-top: 0; // Remove added top space
  }
}

#main .vrtx-frontpage-box.timeline {
  padding-bottom: 0; // Prev/next buttons moved to top, so bottom spacing not needed
  .prev-next-wrapper {
    top: 0;
  }
  .vrtx-box-content {
    &::before {
      border-color: var(--color-orange);
    }
    > div {
      background-color: white;
      &::after {
        background-color: var(--color-orange);
        border: none;
        width: 24px;
        height: 24px;
        top: -68px;
      }
    }
  }
}



/************************************************************
Accordion
************************************************************/

details.accordion-wrapper {
  border: 2px solid var(--color-orange);
  border-width: 2px 0;
  max-width: var(--width-text-max);
  // Prevent double border while consecutive
  & + details {
    border-top: none;
  }
  summary,
  .accordion {
    width: auto;
  }
  summary:focus {
    outline-offset: -2px;
  }
  .accordion {
    padding: 15px 0 20px;
    padding-right: 30px; // Space for icon
    color: var(--color-orange);
    margin: 0;
    &::before {
      background-image: url("../images/plus-orange.svg");
      background-size: 14px auto;
      left: auto;
      right: 0;
    }
    &:hover {
      text-decoration: none;
      @media (pointer: fine) {
        &::before {
          right: 10px;
        }
      }
    }
  }
  &[open] {
    .accordion {
      &::before {
        transform: rotate(45deg);
      }
    }
  }
  h2.accordion {
    @media (@screen-medium) {
      font-size: var(--font-size-large-20);
      &::before {
        background-size: 18px auto;
      }
    }
    @media (@screen-below-medium) {
      font-size: var(--font-size-large-10);
    }
  }
  // h4 accordion same font-size as h3, due to minimum for orange contrast
  h4.accordion {
    font-size: var(--font-size-large-10);
    font-weight: normal;
    line-height: 1.3;
  }
}
// Accordion-end and any non-accordion heading following an accordion
.accordion-wrapper + h2,
.accordion-wrapper + h3,
.accordion-wrapper + h4,
.accordion-end {
  margin-top: 25px;
}
// Exceptions
.vrtx-article-body {
  .accordion-wrapper + h2 {
    margin-top: 50px;
  }
}



/************************************************************
Navigation boxes
Frontpage box class. Requires specific markup.
Used for study program list and possibly elsewhere
************************************************************/

.vrtx-frontpage-box.navigation-boxes {
  .vrtx-box-content {
    > h2 {
      &:not(:first-child) {
        margin-top: 100px;
      }
    }
    // Each box (code similar to exchange-listing)
    > div {
      position: relative;
      border: 1px solid var(--color-dark-80);
      border-width: 1px 0;
      padding: 25px 60px 27px 0; // Right space for arrow
      max-width: var(--width-soft-max);
      // Less arrow-space on small screen
      @media (@screen-below-medium) {
        padding-right: 40px;
      }
      // Prevent double border
      & + div {
        border-top: none;
      }
      h3 {
        margin-bottom: 5px;
        a {
          text-decoration: none;
          // Link cover whole item
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
          &::after {
            content: "";
            background: url("../images/arrow.svg") no-repeat center;
            background-size: 30px auto; // Instead of contain to prevent pixel shifting
            position: absolute;
            right: 15px;
            top: 25px; // Match container top padding
            width: 30px;
            height: 1.3em;
            transition: all .3s;
            @media (@screen-below-medium) {
              background-size: contain; // No hover anim here
              right: 0;
              width: 24px;
            }
          }
          &:hover {
            &::after {
              right: 0;
            }
          }
          .language-marker {
            font-size: .75em;
          }
        }
        & ~ p {
          display: inline-block;
          margin: 0 20px 0 0;
        }
      }
      p {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}



/***********************************************************
Image-grid and image-slide
Markup is identical for these two, so can be switched with just the frontpage box class
************************************************************/

.image-grid,
.image-slide {
  > p:not(:has(img)) {
    display: none;
  }
  figure,
  img {
    margin: 0;
  }
  img {
    // Ready class added with js after adding alt=listing
    &:not(.ready) {
      display: none;
    }
    object-fit: cover;
  }
  figcaption {
    font-size: var(--font-size-base);
    color: inherit;
    margin-top: 15px;
    strong {
      display: block;
    }
    br {
      display: none;
    }
  }
}

// Image-grid/slide in article body
.vrtx-article-body {
  .image-grid,
  .image-slide-prev-next,
  .image-slide {
    @media (@screen-medium) {
      width: calc(100vw - 100px);
    }
    @media (@screen-large) {
      margin-left: -200px;
    }
    @media (@screen-oversized) {
      width: calc(var(--width-total-max) - 100px);
    }
  }
}

// Image-grid
.image-grid {
  display: grid;

  img {
    aspect-ratio: 5/4;
  }
  @media (@screen-large) {
    grid-template-columns: repeat(4, 1fr);
    gap: 150px 20px;
    margin: 150px 0;

    // Layout variations. Repeat for every 8th items
    // Default layout
    > * {
      &:nth-child(8n + 3),
      &:nth-child(8n + 7) {
        grid-column: span 2;
      }
      &:nth-child(8n + 5) {
        grid-column: 3;
      }
      &:nth-child(8n + 8) {
        grid-column: 4;
      }
    }
    // Layout 2
    &.layout-2 {
      > * {
        &:nth-child(8n + 1),
        &:nth-child(8n + 8) {
          grid-column: span 2;
        }
        &:nth-child(8n + 2) {
          grid-column: 4;
        }
        &:nth-child(8n + 3) {
          grid-column: 1;
        }
        &:nth-child(8n + 4) {
          grid-column: 3;
        }
        &:nth-child(8n + 5) {
          grid-column: 4;
        }
        &:nth-child(8n + 7) {
          grid-column: 2;
        }
      }
    }
    // Layout 3
    &.layout-3 {
      > * {
        &:nth-child(8n + 3) {
          grid-column: 4;
        }
        &:nth-child(8n + 4) {
          grid-column: span 2;
        }
        &:nth-child(8n + 7) {
          grid-column: 1;
        }
        &:nth-child(8n + 8) {
          grid-column: 3/5;
        }
      }
    }
  }
  // Simplified layout for small screen
  @media (@screen-below-large) {
    gap: 50px 20px;
    margin: 50px 0;
    > * {
      &:nth-child(odd) {
        padding-right: 25%;
      }
      &:nth-child(even) {
        padding-left: 25%;
      }
      &:nth-child(8n + 3),
      &:nth-child(8n + 7) {
        padding: 0;
      }
    }
  }
}

// Image-grid when following heading
h2,
h3 {
  & + .image-grid {
    margin-top: 50px;
  }
}

// Image-slide prev/next button container
.image-slide-prev-next {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 50px 0 20px;
  .prev {
    .mixin-prev-button;
  }
  .next {
    .mixin-next-button;
  }
}

// Image-slide
.image-slide {
  // Remove scrollbar
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // WebKit
  }
  overflow: auto;
  display: flex;
  gap: 20px;
  margin-bottom: 50px;

  @media (@screen-large) {
    margin: 50px 0 100px;
  }

  // Contained items
  > * {
    flex-shrink: 0;

    // Default three columns
    @media (@screen-large) {
      // Show 3 + hint of next
      flex-basis: calc(32% - var(--space));
    }
    @media (@screen-below-large) {
      // Show 2 + hint of next
      flex-basis: calc(48% - var(--space));
    }
    @media (@screen-below-medium) {
      // Show 1 + hint of next
      flex-basis: calc(94% - var(--space));
    }
  }
}



/*********************************************************
Feed
*********************************************************/

.vrtx-feed {
  .vrtx-image {
    display: block;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all .3s;
  }
  img {
    margin: 0;
  }
  .published-date {
    display: block;
    margin-top: 15px; // In case after title
  }
  .item-title {
    .mixin-fancy-underline-grow;
    display: inline;
    &::before {
      content: ""; // Link to cover entire item
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .item-description {
    margin-top: 15px; // Due to title being inline
  }
  li {
    position: relative;
    &:hover {
      .vrtx-image {
        @media (pointer: fine) {
          border-radius: var(--radius);
        }
      }
      .item-title {
        .mixin-fancy-underline-grow-hover;
      }
    }
  }
}



/*********************************************************
Grid-feed
Feed variant with image-grid layout
*********************************************************/

#main .grid-feed {
  .vrtx-feed {
    .items {
      display: grid;
      align-items: start; // Prevent link height from stretching
      list-style: none;
      padding: 0;
      margin: 0;
      @media (@screen-large) {
        grid-template-columns: repeat(4, 1fr);
        gap: 150px 20px;
        margin: 150px 0;
        li {
          &:nth-child(8n + 3),
          &:nth-child(8n + 7) {
            grid-column: span 2;
          }
          &:nth-child(8n + 5) {
            grid-column: 3;
          }
          &:nth-child(8n + 8) {
            grid-column: 4;
          }
        }
      }
      @media (@screen-below-large) {
        grid-template-columns: 1fr; // Override column-class if present
        gap: 60px 20px;
        margin: 60px 0;
        li {
          &:nth-child(odd) {
            padding-right: 25%;
          }
          &:nth-child(even) {
            padding-left: 25%;
          }
          &:nth-child(8n + 3),
          &:nth-child(8n + 7) {
            padding: 0;
          }
        }
      }
    }
    li {
      display: revert;

      .vrtx-image {
        max-width: 100%;
        border-radius: 0;
        img {
          aspect-ratio: 5/4;
        }
      }
      p {
        margin: 0;
      }
      &:has(:hover) {
        .vrtx-image {
          border-radius: var(--radius);
        }
      }
    }
  }
}
// Grid-feed in frontpage template
#vrtx-frontpage #main .grid-feed {
  .items {
    margin: 0;
  }
}
// Grid-feed in article body
#main .vrtx-article-body .grid-feed {
  @media (@screen-medium) {
    width: calc(100vw - 100px);
  }
  @media (@screen-large) {
    margin-left: -200px;
  }
  @media (@screen-oversized) {
    width: calc(var(--width-total-max) - 100px);
  }
}
// Grid-feed when following heading
#main {
  h2,
  h3 {
    & + .grid-feed .vrtx-feed .items {
      margin-top: 50px;
    }
  }
}



/************************************************************
Jobbnorge feed
************************************************************/

.jobbnorge-feed {
  .vrtx-feed {
    li {
      background-color: white;
      border-radius: var(--radius);
      padding: 30px;
      max-width: var(--width-soft-max);
    }
  }
}



/************************************************************
Events
************************************************************/

// Time and place container. Used in both feed, list and event page
.vrtx-time-and-place-container {
  // Hide title (affects list page and event page)
  .time-and-place {
    display: none;
  }
}

// Event-type. Used in both feed, list and event page
.event-type {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  span {
    border: 1px solid var(--color-dark-80);
    border-radius: var(--radius);
    font-size: var(--font-size-tiny);
    padding: 2px 10px;
  }
}



// Event feed
.vrtx-event-component {
  // Each item
  .vrtx-event-component-item {
    position: relative; // For title summary link cover
  }
  // Date-icon
  .vrtx-date-icon {
    font-size: var(--font-size-large-40);
    background: none;
    display: block; // Override flex
    width: auto;
    padding: 0 0 25px;
    border-bottom: 1px solid;
    margin-bottom: 25px;
    min-height: 100px;
    align-content: end;
  }
  // If end-date present in any child, text breaks, so balance heights
  &:has(.vrtx-date-icon-end) {
    .vrtx-date-icon {
      min-height: 150px;
    }
  }
  .vrtx-date-icon-separator {
    margin: 0; // Was for flex
  }
  .vrtx-date-icon-end {
    display: block; // Break to new line
  }
  .vrtx-date-icon-day {
    display: inline;
    font-weight: normal;
    // Add punctuation except for today
    &:not(.vrtx-date-icon-today) {
      &::after {
        content: ".";
      }
    }
  }
  .vrtx-date-icon-month {
    display: inline;
    font-size: inherit;
  }

  // Content after date-icon

  // If combining picture and date-icon (not allowed)
  .with-picture {
    .vrtx-date-icon {
      position: static; // Override absolute (on top of image)
    }
  }
  .vrtx-list-item-content {
    display: grid; // To change order
    align-content: start;
  }
  .event-type {
    order: 3;
  }
  .vrtx-event-component-title {
    order: 1;
    margin-bottom: 20px;
  }
  .vrtx-event-component-misc {
    order: 2;
    margin: -5px 0 5px;
  }
  // When using date-icon, picture, time and place are hidden
  &:has(.vrtx-date-icon) {
    .vrtx-event-component-picture,
    .vrtx-event-component-misc {
      display: none;
    }
  }
  // Introduction usually not used
  .vrtx-event-component-introduction {
    order: 2;
    p {
      margin-bottom: 20px
    }
  }
  // Title link
  .vrtx-event-component-title.summary {
    .mixin-fancy-underline-grow;
    display: inline;
    // For link to cover entire item
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      text-decoration: none;
      .mixin-fancy-underline-grow-hover;
    }
  }
}



// Events list page
#vrtx-event-listing {
  .vrtx-list-item-content {
    // Flex to change order
    display: flex;
    flex-flow: column;

    .vrtx-title {
      order: 1;
    }
    .vrtx-time-and-place-container {
      order: 2;
      font-weight: bold;
    }
    .description {
      order: 4;
    }
    .event-type {
      order: 3;
      margin-bottom: 15px;
    }
  }
}



// Event page
#vrtx-structured-event {
  .vrtx-introduction {
    margin-bottom: 40px;
  }
  .vrtx-byline {
    margin-bottom: 50px;
  }
  .vrtx-time-and-place-container {
    font-weight: bold;
  }
  .event-type {
    margin-top: 15px;
    font-weight: normal;
  }
  // Add-to-calendar link
  .vrtx-number-of-comments-add-event-container {
    font-weight: normal;
    margin-top: 15px;
  }
  .vrtx-event-organizers {
    h2 {
      font-weight: bold;
    }
  }
}



/************************************************************
Tables
************************************************************/

.main table {
  caption {
    h2,
    h3 {
      margin-bottom: 5px;
    }
  }
  // Opening-hours: Manual class used in fellestekst. Basic solution.
  // &.opening-hours {
  //   th,
  //   td {
  //     border-width: 1px 0;
  //     &:first-child {
  //       padding-left: 0;
  //     }
  //     &:last-child {
  //       padding-right: 0;
  //     }
  //   }
  // }
  // Opening-hours: Manual class used in fellestekst. Advanced solution.
  // Flex-solution with better layout control of caption etc.
  // Tables must follow set layout with two columns etc.
  &.opening-hours {
    display: flex;
    flex-direction: column;
    width: fit-content;
    tr {
      display: flex;
      justify-content: space-between;
      border: 1px solid var(--color-neutral-tint-80);
      border-width: 0 0 1px 0;
      &:first-child {
        border-width: 1px 0;
      }
    }
    th,
    td {
      border: none;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}



/************************************************************
Article & event page. Common elements
************************************************************/

#vrtx-structured-article,
#vrtx-structured-event {
  // Article body
  .vrtx-article-body {
    @media (@screen-oversized) {
      .image-right {
        margin-right: -400px;
      }
      .vrtx-facts-container.vrtx-container-right {
        margin-right: -500px;
      }
    }
    // Increased font-size and spacing above medium screen
    @media (@screen-medium) {
      font-size: 2.2rem;
      h2 {
        &:not(.accordion) {
          font-size: 3.8rem;
        }
      }
      h3 {
        &:not(.accordion) {
          font-size: var(--font-size-large-20);
        }
      }
      p,
      ul,
      ol {
        margin-bottom: 40px;
      }
      ul ul,
      ol ol {
        margin-bottom: 0; // Re-apply to override
      }
      // Top-spacing for headings after accordion
      .accordion-wrapper + h3,
      .accordion-wrapper + h4 {
        margin-top: 40px;
      }
      // Revert increased font-size and spacing for some elements
      table,
      .vrtx-facts-container,
      .accordion-content {
        font-size: var(--font-size-base);
        p,
        ul,
        ol {
          margin-bottom: 25px;
        }
        ul ul,
        ol ol {
          margin-bottom: 0; // Re-apply to override
        }
      }
      .vrtx-facts-container {
        h3 {
          font-size: var(--font-size-large-05);
        }
      }
      .vrtx-contact-component {
        font-size: var(--font-size-base);
      }
      .vrtx-image-gallery-image-description {
        p {
          margin-bottom: 0;
        }
      }
    }
    blockquote {
      color: var(--color-orange);
      &::before {
        font-weight: bold;
        height: .7em;
        text-indent: -.05em;
      }
      @media (@screen-large) {
        width: var(--width-text-max);
      }
      @media (@screen-medium) {
        margin-left: 150px;
        margin-bottom: 50px;
        p:first-child {
          font-size: var(--font-size-large-20);
          line-height: 1.4;
        }
      }
    }
    // Fullscreen image
    .image-center {
      figcaption {
        @media (@screen-large) {
          width: 550px;
          border-radius: var(--radius);
        }
      }
    }
  }
}



/************************************************************
Article page
************************************************************/

#vrtx-structured-article {
  #vrtx-introduction-wrapper {
    display: grid;
    justify-items: start; // So image-text width follows img

    .vrtx-introduction-image {
      order: -1;
    }
    .vrtx-introduction {
      p {
        margin: 0;
      }
    }
  }
  h1,
  .vrtx-introduction-image {
    max-width: none;
    @media (@screen-large) {
      margin-left: -200px; // Reverse content indent
    }
  }
  .vrtx-introduction-image {
    img {
      max-height: none;
    }
  }
}



/************************************************************
Student project article
************************************************************/

#vrtx-structured-article {
  &.vrtx-student-project {
    // Hide breadcrumbs below project list-page (category & year-folders)
    #vrtx-breadcrumb {
      > span {
        // Hide two parent levels (year and category)
        &:nth-last-child(2),
        &:nth-last-child(3) {
          display: none;
        }
        // Mobile show only list page level
        @media (@screen-below-medium) {
          &:nth-last-child(4) {
            display: inline-flex;
          }
        }
      }
    }
    // Author in student-project is student who created the project
    .vrtx-authors {
      color: var(--color-orange);
      font-size: var(--font-size-large-10);
      // Balance spacing with h1
      margin-top: -20px;
      margin-bottom: 40px;

      @media (@screen-large) {
        margin-left: -200px; // Reverse indent
      }
      @media (@screen-medium) {
        font-size: var(--font-size-large-20);
        margin-top: -30px;
      }
      .vrtx-authors-prefix {
        font-weight: normal;
        &::after {
          display: none;
        }
      }
    }
    // Special attributes for student-project data
    .vrtx-special-attributes {
      max-width: var(--width-text-max);
      dl {
        position: relative;
        background-color: var(--color-midnight);
        color: white;
        border-radius: var(--radius);
        padding: 30px;
        padding-top: 70px; // Space for info-symbol
        &::before {
          .mixin-info-symbol;
        }
        @media (@screen-large) {
          float: right;
          width: 400px;
          margin: 10px -100px 40px 40px;
        }
        @media (@screen-oversized) {
          margin-right: -500px;
        }
        dt {
          font-weight: normal;
          &::after {
            content: ":";
          }
        }
        dd {
          font-weight: bold;
        }
      }
    }
    // Manually added class in template file created for editor
    .vrtx-student-project-facts {
      p,
      ul,
      ol {
        margin: 0;
      }
      // Supervisor name in this container if template filled out correctly
      p:nth-child(2) {
        font-weight: bold;
      }
    }
  }
}



/************************************************************
Exchange and person list page filters
************************************************************/

#vrtx-uh-exchange-listing,
#vrtx-uh-person-listing {
  #filters-form {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 0 40px;
    legend {
      font-weight: normal;
    }
    // Dropdown (needs width-limit)
    select {
      width: 250px;
    }
    // Person freetext search
    #vrtx-filter-query {
      input {
        border-width: 1px 1px 2px;
        border-color: var(--color-dark-50);
        border-bottom-color: black;
        width: 250px;
      }
    }
    // Person unit select
    #vrtx-filter-enhet {
      label {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  ul.active-filters {
    a {
      background-color: white;
    }
  }
}



/************************************************************
Exchange list page
************************************************************/

#vrtx-uh-exchange-listing {
  #filters-form {
    gap: 20px;
    @media (@screen-below-medium) {
      flex-direction: column;
    }
    #vrtx-filter-type {
      display: none; // Hide filter for Type
    }
    // Temp. expandable fieldset awaiting select dropdowns
    fieldset.expandable {
      position: relative;
      z-index: 1;
      legend {
        button {
          display: flex;
          align-items: stretch;
          gap: 10px;
          &::after {
            flex-shrink: 0;
            content: "";
            background: url("../images/chevron.svg") no-repeat center;
            rotate: 90deg;
            width: 20px;
          }
        }
      }
      ul {
        position: absolute;
        background-color: white;
        box-shadow: 0 0 10px #0003;
        padding: 15px;
        margin-top: 10px;
        width: 250px;
      }
      li {
        width: auto;
      }
      &:has(button[aria-expanded=true]) {
        z-index: 2;
      }
      &:not(:has(button[aria-expanded=true])) {
        ul {
          display: none;
        }
      }
    }
    .filters-submit {
      display: none;
    }
  }
  #main h2 {
    margin-bottom: 40px;
  }
  // List item (code similar to navigation-boxes)
  .vrtx-exchange-destination-entry {
    position: relative;
    border: 1px solid var(--color-dark-80);
    border-width: 1px 0 0 0;
    padding: 25px 60px 27px 0; // Right space for arrow
    max-width: var(--width-soft-max);
    // Less arrow-space on small screen
    @media (@screen-below-medium) {
      padding-right: 40px;
    }
    &:last-child {
      border-width: 1px 0;
    }
    a {
      display: block;
      margin-bottom: 5px;
      text-decoration: none;
      // Link cover whole item
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .title {
        .mixin-h3;
        margin: 0;
        &::after {
          content: "";
          background: url("../images/arrow.svg") no-repeat center;
          background-size: 30px auto;
          position: absolute;
          right: 15px;
          top: 25px; // Match container top padding
          width: 30px;
          height: 1.3em;
          transition: all .3s;
          @media (@screen-below-medium) {
            background-size: contain; // No hover anim here
            right: 0;
            width: 24px;
          }
        }
      }
      &:hover {
        .title {
          &::after {
            right: 0;
          }
        }
      }
    }
    > span {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}



/************************************************************
Exchange destination
************************************************************/

#vrtx-uh-exchange-destination {
  #vrtx-content {
    @media (@screen-large) {
      margin-left: 200px;
      h1 {
        margin-left: -200px;
      }
    }
  }
  // Facts boxes: Horizontal facts box above uh-infobox and vertical facts box inside
  .vrtx-uh-facts-horizontal,
  .vrtx-uh-facts-vertical {
    dt,
    dd {
      display: inline-block;
    }
    dt {
      &::after {
        content: ":";
      }
    }
  }
  .vrtx-uh-facts-horizontal {
    dd {
      margin-right: 15px;
    }
  }
  // Exchange content. Wraps everything after facts-horizontal
  #vrtx-uh-exchange-content {
    > *:not(.vrtx-uh-infobox) {
      max-width: var(--width-text-max);
    }
    h2:not(.accordion) {
      margin-top: 50px;
      margin-bottom: 15px;
    }
    // Infobox. Contains introduction-image and facts-container
    .vrtx-uh-infobox {
      margin-bottom: 80px;
      @media (@screen-large) {
        display: flex;
        gap: 20px;
      }
      .vrtx-introduction-image {
        flex-basis: 60%;
        img {
          border-radius: var(--radius);
          max-height: none;
        }
        @media (@screen-large) {
          display: grid; // To stretch img height
          position: relative;
          margin: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .vrtx-imagetext {
            position: absolute; // To go below grid-container
            left: 0;
            top: 100%;
          }
        }
      }
      // Facts-container
      // Contains facts-vertical dl + description and info about available spaces
      .vrtx-facts-container {
        position: relative;
        flex-basis: 40%;
        flex-grow: 1;
        max-width: var(--width-text-max);
        padding: 70px 30px 30px; // Top space for info-symbol
        &::before {
          .mixin-info-symbol;
        }
        // Remove redundant H2s
        h2 {
          &:first-child {
            display: none;
          }
        }
        .vrtx-uh-facts-vertical {
          margin: 0;
          dt {
            &.vrtx-field-website {
              display: block;
              margin-bottom: 15px;
              &::after {
                display: none;
              }
            }
          }
          dd {
            &::after {
              content: "";
              display: block;
              margin-bottom: 15px;
            }
            &.vrtx-field-website {
              display: none; // This seems to come out empty, so removing. Slight risk.
            }
          }
        }
        .vrtx-uh-field-description {
          p {
            margin-bottom: 15px;
          }
        }
        .vrtx-uh-field-available-places {
          strong,
          p {
            display: inline-block;
          }
          strong {
            &::after {
              content: ":";
            }
          }
          p {
            margin: 0;
          }
        }
      }
    }
    // Container below infobox
    // Most have automatic accordions, except vrtx-field-application (it seems)
    // Accordions are each in an outer container, so not directly consecutive
    .vrtx-uh-content {
      div:has(.accordion-wrapper) + div:has(.accordion-wrapper) {
        .accordion-wrapper {
          border-top: none;
        }
      }
    }
    .vrtx-field-programmes {
      h2 {
        font-size: inherit;
        line-height: inherit;
        font-weight: bold;
      }
      .vrtx-uh-linklist {
        .link-list;
        li {
          a {
            font-weight: normal;
          }
        }
      }
    }
  }
}



/************************************************************
Person list page
************************************************************/

#vrtx-uh-person-listing {
  // Filters
  #filters-form {
    // Text search
    #vrtx-filter-query {
      legend {
        margin-bottom: 5px;
      }
      // Hide label (is same as legend)
      label {
        .invisible;
      }
    }
    ul {
      margin: 0;
    }
  }
  // New H2 element in template
  .vrtx-person-listing-counter {
    margin-bottom: 40px;
  }
  // Main person list
  ul.vrtx-person-listing {
    display: grid;
    gap: 80px 40px;
    list-style: none;
    padding: 0;
    margin-bottom: 60px;
    @media (@screen-large) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (@screen-below-large) and (@screen-medium) {
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      &:has(.vrtx-person-listing-name a:hover) {
        border-radius: var(--radius);
      }
    }
    // Name link and img
    .vrtx-person-listing-name {
      a {
        display: block;
        text-decoration: none;
        // Image and pseudo image placeholder
        img,
        &:not(:has(img))::before {
          display: block;
          aspect-ratio: 3/4;
          width: 100%;
          margin-bottom: 15px;
          transition: all .3s;
        }
        img {
          object-fit: cover;
          filter: grayscale(1);
        }
        &:not(:has(img))::before {
          content: "";
          background: var(--color-dark-90);
        }
        // Name text
        .vrtx-person-listing-name-text {
          .mixin-fancy-underline-grow;
          font-weight: bold;
        }
        // Hover
        &:hover {
          img,
          &::before {
            @media (pointer: fine) {
              border-radius: var(--radius);
            }
          }
          .vrtx-person-listing-name-text {
            .mixin-fancy-underline-grow-hover;
          }
        }
      }
    }
    // Mobile layout with img on left
    @media (@screen-below-medium) {
      gap: 20px;
      li {
        position: relative;
        padding-left: 100px;
        min-height: 110px; // Accomodate img height
        .vrtx-person-listing-name {
          a {
            img,
            &:not(:has(img))::before {
              position: absolute;
              left: 0;
              top: 0;
              width: 80px;
            }
          }
        }
      }
    }
    // Hide contact details
    .vrtx-person-listing-phone,
    .vrtx-person-listing-mobilephone,
    .vrtx-person-listing-email {
      display: none;
    }
  }
}



/************************************************************
Publications (used for person and project pages)
************************************************************/

#vrtx-publications-wrapper {
  #vrtx-publication-tabs {
    > ul {
      @media (@screen-large) {
        background-color: var(--color-background-main);
      }
      a {
        border: var(--border);
        &[aria-expanded=true] {
          border-color: transparent;
        }
        &[aria-expanded=false] {
          &:hover {
            background-color: white;
          }
        }
      }
    }
  }
  .vrtx-publication-summary {
    a {
      .mixin-underline;
      gap: 8px;
      border: none;
      padding: 0;
      margin: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
}



/************************************************************
Person page
************************************************************/

#vrtx-uh-person {
  #vrtx-person-top-area {
    @media (@screen-large) {
      grid-template-rows: auto 1fr auto;
      column-gap: 60px;
      margin-top: 30px;
    }
    h1 {
      font-weight: bold;
      @media (@screen-large) {
        margin-bottom: 10px;
        grid-column: auto;
      }
    }
    img {
      @media (@screen-large) {
        width: 300px;
        grid-row: 1/4;
      }
    }
    .vrtx-position-unit {
      color: var(--color-orange);
      font-size: var(--font-size-large-10);
      line-height: 1.3;
      margin-bottom: 50px;
      span {
        @media (@screen-large) {
          display: inline;
        }
      }
      .vrtx-position {
        font-weight: bold;
      }
      // Separator if has both position and unit
      &:has(.vrtx-unit) {
        .vrtx-position::after {
          @media (@screen-large) {
            content: "";
            border-left: 1px solid;
            margin: 0 10px 0 15px;
          }
        }
      }
    }
    .vrtx-person-contact-info {
      @media (@screen-large) {
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 60px;
        border-top: 1px solid;
        padding-top: 15px;
      }
      dl {
        margin: 0;
      }
      dt,
      dd {
        @media (@screen-medium) {
          display: inline;
        }
      }
      dt {
        font-weight: normal;
        &::after {
          @media (@screen-medium) {
            content: ":";
            margin-right: 5px;
          }
        }
      }
      dd {
        font-weight: bold;
        // Line separator
        &::after {
          @media (@screen-medium) {
            content: "";
            display: block;
            margin-bottom: 10px;
          }
        }
      }
      #vrtx-press-photo {
        .language-marker {
          display: none;
        }
      }
      #vrtx-person-vcard {
        display: none;
      }
    }
  }
  // Elements below top area
  #vrtx-person-main-content-wrapper {
    .vrtx-article-body {
      border: none;
      padding: 0;
      margin-bottom: 50px;
    }
    .vrtx-groups {
      margin-bottom: 50px;
      h2 {
        margin-bottom: 20px;
      }
    }
    ul {
      &.only-links {
        .link-list;
        li {
          a {
            font-weight: normal;
          }
        }
      }
    }
  }
  // Hide published/edited date
  .vrtx-date-info {
    display: none;
  }
}



/************************************************************
Student projects
************************************************************/

#configurable-filtered-listing {
  .configurable-filter {
    margin-bottom: 60px;
    select {
      max-width: 170px;
    }
  }
  // Hide feedback message / item counter unless no hits (except for screen-reader)
  .feedback:not(.no-hits) {
    .invisible;
  }
  ul {
    @media (@screen-large) {
      grid-template-columns: repeat(4, 1fr);
      gap: 150px 20px;
      // Li numbering done with js. Can't use nth-child due to inactive items
      // n3 = every 3rd item and so on, in a cycle of 8
      li {
        &.n3 {
          grid-column: span 2;
        }
        &.n5 {
          grid-column: 3;
        }
        &.n7 {
          grid-column: span 2;
        }
        &.n8 {
          grid-column: 4;
        }
      }
    }
    @media (@screen-below-large) {
      grid-template-columns: 1fr;
      gap: 60px 20px;
      li {
        // Alternate right/left
        // 4th and 7th excluded, so will be full width
        &.n1,
        &.n3,
        &.n5 {
          padding-right: 25%;
        }
        &.n2,
        &.n6,
        &.n8 {
          padding-left: 25%;
        }
      }
    }
  }
  li {
    &::before {
      background-color: var(--color-background-main);
    }
    img {
      aspect-ratio: 5/4;
      margin-bottom: 15px;
      transition: all .3s;
    }
    h3 {
      .mixin-fancy-underline-grow;
      display: inline; // Needed for fancy underline
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      margin: 0;
      // First element after used to break inline
      & + *::before {
        content: "";
        display: block;
      }
    }
    a {
      &:hover {
        img {
          @media (pointer: fine) {
            border-radius: var(--radius);
          }
        }
        h3 {
          text-decoration: none;
         .mixin-fancy-underline-grow-hover;
        }
      }
    }
    span {
      &:not(:last-child)::after {
        color: var(--color-dark-50);
      }
      // Hide some facets
      &.fields-of-expertise {
        display: none;
      }
    }
  }
}



/************************************************************
Contact component

Also targetting div that has contact component as direct and only child
This allows more flexibility, such as use of felletekst and component-insertion with both div and p
Also targetting anonynous div following this pattern to allow generic contact info that is not a component
************************************************************/

.vrtx-contact-component,
div:has(>.vrtx-contact-component:only-child),
div:has(>.vrtx-contact-component:only-child) + div {
  display: inline-block;
  vertical-align: top;
}
.vrtx-contact-component {
  position: relative; // For link-cover
  margin: 0 40px 40px 0;
  max-width: 250px;
  // Img and placeholder
  img,
  &:not(:has(img))::before {
    aspect-ratio: 3/4;
    object-fit: cover;
    margin: 0 0 15px 0;
    width: 250px;
    transition: all .3s;
  }
  &:not(:has(img))::before {
    content: "";
    display: block;
    background: var(--color-dark-90);
  }
  .name {
    font-weight: bold;
    margin: 0;
    a {
      text-decoration: none;
      .mixin-fancy-underline-grow;
      // Link-cover
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .title {
    margin-bottom: 10px;
  }
  dl {
    position: relative; // Lift above link-cover
    font-size: var(--font-size-tiny);
  }
  dt {
    display: none;
  }
  // Hover (using link-cover)
  &:has(.name a:hover) {
    img,
    &::before {
      @media (pointer: fine) {
        border-radius: var(--radius);
      }
    }
    .name a {
      .mixin-fancy-underline-grow-hover;
    }
  }
}



/******************************************************************
Study program pages: Frontpage, plan, subject. Common elements.
******************************************************************/

// Context box. NOT IN USE
// Present on various pages in studies context. Typically contains link to programme frontpage.
// Additional class "vrtx-context-box-linked" can be targetted if necessary to separate.
.vrtx-context-box {
  display: none;
}

// Status message for programmes that don't admit students
.vrtx-context-message-box {
  margin-bottom: var(--space);
  .row {
    padding: 0;
    margin: 0;
  }
  .content-wrapper {
    display: grid;
    grid-template-columns: 30px auto;
    gap: 10px;
    width: fit-content;
    background-color: white;
    padding: 15px 25px 15px 15px;
    // Icon
    &::before {
      content: "";
      background: url("../images/alert.svg") no-repeat center;
      background-size: contain;
    }
  }
}

#vrtx-fs-studieprogram-facts,
#vrtx-fs-emne-facts {
  // Remove inherited facts-container styles
  background: none;
  border-radius: 0;
  h2 {
    .invisible; // Redundant. Hidden except for screen-reader.
  }
  h4 {
    display: none; // Removed. Wrong in hierarchy.
  }
  dl {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px 60px;
    .dg {
      border-top: 1px solid var(--color-dark-80);
      padding-top: 10px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}



/************************************************************
Study program frontpage
************************************************************/

#vrtx-fs-studieprogram-frontpage {
  // Lagre screen page layout
  #vrtx-main-content {
    @media (@screen-large) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 120px;
      > * {
        grid-column: span 2;
      }
      #vrtx-fs-studieprogram-content {
        grid-column: 1;
      }
      #vrtx-fs-studieprogram-contact {
        grid-column: 2;
        grid-row: span 2;
      }
      .plan-links {
        grid-column: 1;
      }
    }
  }
  #vrtx-fs-studieprogram-intro {
    margin-bottom: 30px;
    @media (@screen-large) {
      display: flex;
      column-gap: 60px;
      > * {
        flex: 1;
      }
      .vrtx-introduction-image {
        order: 2;
      }
      &:has(img) {
        .vrtx-introduction {
          font-size: var(--font-size-large-10);
        }
      }
    }
    .vrtx-introduction-image {
      margin-bottom: 30px;
      .vrtx-imagetext {
        display: none; // Image text hidden
      }
    }
  }
  #vrtx-fs-studieprogram-facts-and-actions {
    margin-bottom: 100px;
    // Folders
    .vrtx-subfolder-menu {
      margin: 0;
    }
  }
  // Apply-button
  #vrtx-fs-studieprogram-search-button {
    margin-bottom: 15px;
    a {
      .button-loud;
    }
  }
  #vrtx-fs-studieprogram-content {
    margin-bottom: 100px;
    .vrtx-article-body {
      margin: 0;
    }
  }
  #vrtx-fs-studieprogram-contact {
    @media (@screen-below-large) {
      margin-bottom: 100px;
    }
  }
  .plan-links {
    .current-plan {
      display: block;
      width: fit-content;
      margin-bottom: 15px;
    }
  }
  #vrtx-fs-studieprogram-vacancies {
    font-weight: bold;
    margin-bottom: 50px;
  }
  .vrtx-article-body {
    max-width: none;
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
  .accordion-wrapper {
    max-width: none;
  }
  // Link-special custom variant for this template
  .link-special {
    position: relative;
    display: block;
    border: 2px solid;
    border-radius: var(--radius);
    padding: 7.5% 10% 8.5% 7.5%;
    color: var(--color-orange);
    font-size: var(--font-size-large-50);
    line-height: 1.2;
    text-decoration: none;
    @media (@screen-below-large) {
      font-size: var(--font-size-large-20);
      line-height: 1.3;
    }
    &::after {
      content: "";
      background: url("../images/arrow-orange.svg") no-repeat center;
      background-size: .75em auto;
      position: absolute;
      width: .75em;
      height: 1.3em;
      margin-left: .25em;
      transition: all .3s;
    }
    &:hover {
      &::after {
        margin-left: .5em;
      }
    }
  }
  // Stories (has three-column-feed class)
  #vrtx-fs-studieprogram-stories {
    &,
    h2:not(:first-child) {
      margin-top: 100px;
      @media (@screen-large) {
        margin-top: 150px;
      }
    }
    // Student project feed
    .vrtx-feed {
      .items {
        @media (@screen-large) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
      .item-title {
        font-size: inherit;
        font-weight: bold;
      }
      .vrtx-image {
        border-radius: 0;
      }
      img {
        aspect-ratio: 5/4;
      }
      li:hover {
        .vrtx-image {
          border-radius: var(--radius);
        }
      }
    }
  }
}



/************************************************************
Study plan and subject page. Common elements
************************************************************/

#vrtx-fs-studieprogram-document,
#vrtx-fs-emne {
  h1 {
    margin-bottom: 40px;
    @media (@screen-large) {
      margin-bottom: 60px;
    }
  }
  .facts-wrapper,
  .connected-links {
    margin-bottom: 60px;
  }
  .toc-and-content {
    display: grid;
    gap: 60px;
    @media (@screen-large) {
      grid-template-columns: 1fr 1fr 1fr; // Triple to align with facts layout
      align-items: start;
    }
  }
  #toc-wrapper {
    background-color: white;
    padding: 25px;
    border-radius: var(--radius);
    @media (@screen-large) {
      position: sticky;
      top: 10px;
      max-height: calc(100vh - 20px);
      overflow: auto;
    }
    h2 {
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 10px;
    }
    #toc {
      margin: 0;
    }
    ul {
      list-style: none;
      a {
        position: relative;
        display: block;
        font-weight: normal;
        text-decoration: none;
        &::before {
          content: "";
          position: absolute;
          background: url("../images/arrow.svg") no-repeat center;
          background-size: contain;
          width: 18px;
          height: 1.5em;
          left: -30px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  #content-wrapper {
    max-width: var(--width-text-max);
    @media (@screen-large) {
      grid-column: span 2;
    }
    h2 {
      margin: 50px 0 20px 0; // Match vrtx-article-body, which is part of this
    }
    > h2:first-child {
      margin-top: 0;
    }
  }
}



/************************************************************
Study plan model
************************************************************/

.vrtx-fs-study-model {
  h3 {
    font-weight: bold;
  }
  h4 {
    font-weight: normal;
    font-size: var(--font-size-large-10);
    margin-bottom: 5px;
  }
  .course-list {
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
    li {
      border: 1px solid var(--color-dark-80);
      border-width: 1px 0 0 0;
      &:last-child {
        border-width: 1px 0;
      }
      > * {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 40px; // Must be enough for arrow
      }
      a {
        text-decoration: none;
        // Course name when linked
        .course-name {
          font-weight: bold;
          &::after {
            content: "";
            background: url("../images/arrow.svg") no-repeat center;
            background-size: contain;
            padding-right: 24px;
            margin-left: 10px;
          }
        }
        &:hover {
          .course-name {
            text-decoration: underline;
          }
        }
      }
      .course-description {
        padding: 15px 0 25px;
      }
      .course-code {
        display: block;
        font-size: var(--font-size-tiny);
      }
      .course-not-linked-info {
        display: none; // Hide info about there being no link, since there is no link :)
      }
      .course-study-points {
        align-content: center;
        text-align: center;
        background-color: white;
        padding: 0 5px; // Safety valve
        min-width: 80px;
        line-height: 1.1; // For baseline with course-name, using this and number span
        @media (@screen-below-medium) {
          min-width: 60px;
        }
        span {
          // Number
          &:first-child {
            display: block;
            font-weight: bold;
            margin-top: 4px; // For baseline with course name
          }
          &:last-child {
            font-size: var(--font-size-tiny);
          }
        }
      }
      // Non-mandatory / optional courses. Not sure if there are any.
      &:not(.mandatory) {
        .course-study-points span:first-child {
          &::before {
            content: "(";
          }
          &::after {
            content: ")";
          }
        }
      }
    }
  }
}



/************************************************************
Projects and research groups
************************************************************/

// Alphabetical listing version.
// May not be used, but kept here in case.
// Note: There is one of these per letter
.vrtx-alphabetical-project-listing,
.vrtx-alphabetical-research-group-listing {
  padding: 0;
  &,
  ul {
    list-style: none;
  }
  font-size: var(--font-size-large-50); // Alphabet letters
  font-weight: bold;
  ul {
    .link-list;
    font-size: var(--font-size-base); // Normalize font
    li {
      a {
        font-weight: normal;
      }
    }
  }
}

// Research projects list page
#vrtx-project-listing {
  .vrtx-introduction {
    @media (@screen-medium) {
      margin-bottom: 80px;
    }
  }
}

// Project and research group page
// NOTE: Same page id, but some different content
#vrtx-structured-project {
  // Project attributes: duration etc.
  .time-and-place-columns {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    dt,
    dd {
      display: inline;
    }
    dt {
      margin-right: 5px;
      &::after {
        content: ":";
      }
    }
  }
  #vrtx-main-content {
    // All h2s same as in article-body
    h2 {
      margin: 50px 0 20px;
    }
  }
  // Project type
  .sub-title {
    display: inline-block;
    border: 1px solid var(--color-dark-80);
    border-radius: var(--radius);
    font-size: var(--font-size-tiny);
    padding: 2px 10px;
  }
  .vrtx-article-body {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
  // Tags or date info will be at bottom
  .vrtx-tags,
  .vrtx-date-info {
    margin-top: 80px;
  }
  // If tags present, no top-space on date-info
  .vrtx-tags + .vrtx-date-info {
    margin-top: 0;
  }
}



/************************************************************
Consent banner
************************************************************/

// Initial dialog
.consent {
  background-color: white;
}

// Settings dialog
.consent-settings .consent-settings-inner {
  .consent-settings-type {
    padding: 30px;
    border-radius: var(--radius);
  }
  h3 {
    color: inherit;
  }
  li {
    background: none;
    padding: 0;
    font-weight: bold;
  }
  .consent-settings-type {
    background-color: var(--color-background-main);
  }
  .accordion {
    padding: 10px 0;
  }
}



/************************************************************
For ansatte
************************************************************/

body.for-ansatte {
  // Breadcrumbs
  #breadcrumbs {
    // Include second to last crumb on mobile, to show home page until reaching deep level
    span:nth-last-child(3) {
      @media (@screen-below-medium) {
        display: inline-flex;
      }
    }
  }
  // Display breadcrumbs on the-frontpage
  &.the-frontpage {
    #breadcrumbs {
      display: block;
      // Display also when active to always indicate "For ansatte"
      .vrtx-breadcrumb-active {
        display: inline-flex;
        color: var(--color-neutral-tint-25);
      }
    }
  }
}


