// x-people.less

/*
Note that there are different templates for regular and uh-version
There is also a hiof-person
*/

// vrtx-person-listing table (uh-version uses ul with same class)
table.vrtx-person-listing {
  width: 100%;
  th,
  td {
    vertical-align: top;
    border: none;
  }
  th {
    font-weight: normal;
    white-space: nowrap;
  }
  thead {
    background: none;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: var(--color-neutral-tint-95);
    }
    td {
      @media (@screen-large) {
        padding: 20px 10px;
      }
    }
    // Name cell (includes, image, name and title)
    .vrtx-person-listing-name {
      @media (@screen-large) {
        position: relative;
        padding-left: 150px; // Space for image
        height: 190px; // Works like min-height
      }
      .vrtx-image {
        position: absolute;
        border: 1px solid var(--color-neutral-tint-90);
        width: 100px;
        height: 150px;
        left: 10px;
        @media (@screen-large) {
          left: 20px;
        }
      }
      img {
        aspect-ratio: auto; // Container link forces ratio
        height: 100%;
        margin: 0;
      }
      // Name
      a:not(.vrtx-image) {
        font-weight: bold;
        @media (@screen-medium) {
          font-size: var(--font-size-large-10);
        }
      }
      // Job title
      span {
        display: block;
      }
    }
  }
  @media (@screen-below-large) {
    &,
    caption,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    th,
    td {
      padding: 0;
    }
    tbody {
      tr {
        position: relative;
        padding: 20px 10px 20px 130px; // Left space for image
        min-height: 190px;
      }
    }
  }
}



// vrtx-person page
#vrtx-person {
  .vrtx-article-body {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
}
.vrtx-person-contactinfo {
  .vrtx-label {
    display: block;
    font-weight: bold;
  }
}
.vrtx-person-contact-info-line {
  margin-bottom: 15px;
}



// uh-person-listing page
// Template updated Sep. 2024
#vrtx-uh-person-listing {
  // New H2 element
  .vrtx-person-listing-counter {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}



// uh-person page
// Template updated Sep. 2024
#vrtx-uh-person {
  #vrtx-person-top-area {
    display: grid;
    margin-bottom: 50px;
    img {
      width: 250px;
    }
    .vrtx-position-unit {
      margin-bottom: 20px;
      span {
        display: block;
      }
    }
    .vrtx-person-contact-info-list {
      dd {
        margin-bottom: 20px;
      }
    }
    #vrtx-person-contact-info-press-photo-vcard {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    // Layout (assuming image present)
    @media (@screen-medium) {
      grid-template-columns: auto 1fr;
      column-gap: 40px;
      h1 {
        grid-column: 1/3;
      }
      img {
        grid-row: 2/5;
        margin: 0;
      }
      .vrtx-position-unit {
        grid-column: 2;
      }
    }
    // In no image, disable grid layout
    &:not(:has(img)) {
      display: block;
    }
  }
}


