// x-crisis-banner.less

/*
(uio-version replaced Oct. 2024)
*/

#crisis-banner,
#crisis-banner-demo {
  background-color: #444;
  padding: 30px 15px;
  font-size: var(--font-size-base);
  * {
    color: white;
  }
  p {
    margin: 0;
  }
  .crisis-banner-inner {
    text-align: center;
    &::before {
      content: "";
      background: url("../images/alert-2-white.svg") no-repeat center;
      background-size: contain;
      padding-left: 24px;
      margin-right: 5px;
    }
  }
}
#crisis-banner {
  position: relative;
  z-index: 10; // Same level as header
}
